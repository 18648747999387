import React from 'react';
import { isFunction } from 'lodash';
import { createHtmlPortalNode, InPortal, OutPortal } from 'react-reverse-portal';
import { useInViewport, ViewportContext } from 'components/in-viewport';

type ChildParams = {
  visible: boolean;
};

type VirtualizationWrapperProps = {
  children: React.ReactNode | ((params: ChildParams) => React.ReactNode);
  className?: string;
  disableVirtualization?: boolean;
  placeholder: React.ReactNode;
};

export const VirtualizationWrapper: React.FC<VirtualizationWrapperProps> = ({
  children,
  disableVirtualization,
  ...restProps
}) => {
  if (disableVirtualization) {
    return <>{children}</>;
  }

  return <VirtualizationWrapperInner {...restProps} children={children} />;
};

const VirtualizationWrapperInner: React.FC<Omit<VirtualizationWrapperProps, 'active'>> = ({
  className = '',
  children,
  placeholder,
}) => {
  const portalNode = React.useMemo(
    () =>
      createHtmlPortalNode({
        attributes: {
          class: className,
        },
      }),
    [className],
  );
  const viewport = ViewportContext.useCreateViewportContext();
  const [visible, setVisible] = React.useState(false);
  const [rendered, setRendered] = React.useState(false);
  const [domContainer, setDomContainer] = React.useState<HTMLElement | null>(null);
  const onShow = React.useCallback(() => setVisible(true), []);
  const onHide = React.useCallback(() => setVisible(false), []);

  useInViewport({
    viewport,
    debounceMs: 64,
    onShow,
    onHide,
    domContainer,
  });

  React.useEffect(() => {
    if (visible && !rendered) {
      setRendered(true);
    }
  }, [visible, rendered]);

  const getContent = () => (isFunction(children) ? children({ visible }) : children);

  return (
    <div className={className} ref={(ref) => setDomContainer(ref)}>
      <InPortal node={portalNode} children={rendered ? getContent() : null} />
      {visible ? <OutPortal node={portalNode} visible /> : placeholder}
    </div>
  );
};
