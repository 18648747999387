// Libs
import React, { useCallback } from 'react';

// App
import { Layout } from '../layout/Layout';
import { bemBlock } from '../../modules/bem';

// Module
import './HorizontalSplitter.less';

type SplitRateOrLeftWidthPx =
  | {
      splitRate: number;
      leftWidthPx?: undefined;
    }
  | {
      splitRate?: undefined;
      leftWidthPx: number;
    };

type HorizontalSplitterProps = {
  className?: string;
  left: React.ReactNode;
  right?: React.ReactNode;
  minSplitRate?: number;
  maxSplitRate?: number;
  minLeftPx?: number;
  minRightPx?: number;
  splitterWidthPx?: number;
  splitterHandleExtensionLeftPx?: number;
  splitterHandleExtensionRightPx?: number;
  splitterContents?: React.ReactNode;
  splitMoved(newSplitRate: number, newLeftWidthPx: number): void;
} & SplitRateOrLeftWidthPx;

const block = bemBlock('horizontal-splitter');

export const HorizontalSplitter: React.FC<HorizontalSplitterProps> = ({
  className,
  left,
  right,
  splitRate,
  leftWidthPx,
  minSplitRate = 0,
  maxSplitRate = 1,
  minLeftPx = 0,
  minRightPx = 0,
  splitterWidthPx = 2,
  splitterHandleExtensionLeftPx = 0,
  splitterHandleExtensionRightPx = 4,
  splitterContents = null,
  splitMoved,
}) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const sliderRef = React.useRef<HTMLDivElement | null>(null);
  const ghostRef = React.useRef<HTMLDivElement | null>(null);
  const pointerOffset = React.useRef<number>(0);
  // PointerEvents may come from different sources, we will store the id of
  // pointer we are interacting with and ignore other pointers.
  // This will double as a synchronous state for "are we dragging".
  const pointerId = React.useRef<number>();

  const clampedCssValue = useCallback(
    (unclamped: string): string => {
      return `clamp(
        max(${100 * minSplitRate}%, ${minLeftPx}px),
        ${unclamped},
        min(${100 * maxSplitRate}%, calc(100% - ${minRightPx + splitterWidthPx}px)))`;
    },
    [minSplitRate, maxSplitRate, minLeftPx, minRightPx, splitterWidthPx],
  );

  const handlePointerDown = useCallback(
    (event: React.PointerEvent<HTMLElement>) => {
      if (!containerRef.current || !sliderRef.current || !ghostRef.current) {
        return;
      }

      if (pointerId.current) {
        // Ignore irrelevant event.
        return;
      }

      if (event.pointerType !== 'touch' && event.buttons !== 1) {
        // Ignore drag with other buttons than the main one.
        return;
      }

      // Make sure all move events get dispatched directly to this element. This is better
      // for performance and simplifies handling.
      sliderRef.current.setPointerCapture(event.pointerId);
      pointerId.current = event.pointerId;
      const { left: containerLeft } = containerRef.current.getBoundingClientRect();
      const { left: sliderLeft } = sliderRef.current.getBoundingClientRect();
      // Store horizontal offset from the pointer position to the slider's left edge.
      // We will add it as a correction when the mouse moves.
      pointerOffset.current = sliderLeft - event.clientX;
      const posFromMouse = event.clientX - containerLeft + pointerOffset.current;
      ghostRef.current.style.left = clampedCssValue(`${posFromMouse}px`);
      ghostRef.current.style.display = 'block';
    },
    [clampedCssValue],
  );

  const handlePointerMove = useCallback(
    (event: React.PointerEvent<HTMLElement>) => {
      if (!containerRef.current || !ghostRef.current) {
        return;
      }

      if (event.pointerId !== pointerId.current) {
        // Ignore irrelevant event.
        return;
      }

      const { left: containerLeft } = containerRef.current.getBoundingClientRect();
      // Update ghost position.
      const posFromMouse = event.clientX - containerLeft + pointerOffset.current;
      ghostRef.current.style.left = clampedCssValue(`${posFromMouse}px`);
    },
    [clampedCssValue],
  );

  const handleDragEndingEvent = useCallback(
    (event: React.PointerEvent<HTMLElement>) => {
      if (!containerRef.current || !sliderRef.current || !ghostRef.current) {
        return;
      }

      if (event.pointerId !== pointerId.current) {
        // Ignore irrelevant event.
        return;
      }

      const newPosPx = parseFloat(getComputedStyle(ghostRef.current).left);
      sliderRef.current.releasePointerCapture(event.pointerId);
      pointerId.current = undefined;
      ghostRef.current.style.display = '';
      const { width: containerWidth } = containerRef.current.getBoundingClientRect();

      // Treating lostpointercapture the same as pointerup is the result of NPT-13014.
      // See comment there for detailed explanation and context:
      // https://neptune-labs.atlassian.net/browse/NPT-13014?focusedCommentId=16957
      if (event.type === 'pointerup' || event.type === 'lostpointercapture') {
        splitMoved(newPosPx / containerWidth, newPosPx);
      }
    },
    [splitMoved],
  );

  const leftWidthCss = splitRate !== undefined ? `${100 * splitRate}%` : `${leftWidthPx}px`;

  return (
    <Layout.Row
      wrap="nowrap"
      className={block({ extra: className })}
      position="relative"
      overflow="hidden"
      elementRef={containerRef}
    >
      {left && (
        <div
          className={block('left')}
          style={{
            width: right ? clampedCssValue(leftWidthCss) : '100%',
          }}
        >
          {left}
        </div>
      )}
      {right && (
        <>
          {left && (
            <div
              className={block('slider')}
              style={{ width: `${splitterWidthPx}px` }}
              onPointerDown={handlePointerDown}
              onPointerMove={handlePointerMove}
              onPointerUp={handleDragEndingEvent}
              onPointerCancel={handleDragEndingEvent}
              onLostPointerCapture={handleDragEndingEvent}
              ref={sliderRef}
            >
              {splitterContents}
              <div
                className={block('slider-handle')}
                style={{
                  left: `${-splitterHandleExtensionLeftPx}px`,
                  right: `${-splitterHandleExtensionRightPx}px`,
                }}
              />
            </div>
          )}
          <div className={block('right')}>{right}</div>
          {left && (
            <div
              ref={ghostRef}
              style={{
                width: `${
                  splitterWidthPx + splitterHandleExtensionLeftPx + splitterHandleExtensionRightPx
                }px`,
                transform: `translateX(${-splitterHandleExtensionLeftPx}px)`, // Using transform, so that it is always added to left.
              }}
              className={block('slider-ghost')}
            />
          )}
        </>
      )}
    </Layout.Row>
  );
};
