// Libs
import React from 'react';
import { bemBlock } from '../../modules/bem';
import * as text from '../../modules/text';

// Module
import './UITooltip.less';

type TooltipUIProps = {
  className?: string;
  dataRole?: string;
  style?: React.CSSProperties;
  noPointerEvents?: boolean;
};

const block = bemBlock('n-UITooltip');

export const UITooltip: React.FC<TooltipUIProps> = React.forwardRef<HTMLDivElement, TooltipUIProps>(
  ({ children, className, dataRole, style, noPointerEvents }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={block({
          extra: [className, text.wordBreakClassName('break-word')],
          modifiers: { noPointerEvents },
        })}
        children={children}
        data-role={dataRole}
      />
    );
  },
);
