/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributePropertyFilterDTO
 */
export interface AttributePropertyFilterDTO {
    /**
     * Required attribute property name
     * @type {string}
     * @memberof AttributePropertyFilterDTO
     */
    propertyName: string;
    /**
     * Required attribute property values (match any)
     * @type {Array<string>}
     * @memberof AttributePropertyFilterDTO
     */
    propertyValues: Array<string>;
}

export function AttributePropertyFilterDTOFromJSON(json: any): AttributePropertyFilterDTO {
    return AttributePropertyFilterDTOFromJSONTyped(json, false);
}

export function AttributePropertyFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributePropertyFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyName': json['propertyName'],
        'propertyValues': json['propertyValues'],
    };
}

export function AttributePropertyFilterDTOToJSON(value?: AttributePropertyFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'propertyName': value.propertyName,
        'propertyValues': value.propertyValues,
    };
}


