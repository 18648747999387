import { ArrayElementType } from 'common/utility-types';

export const availableAttributeTypes = [
  'artifact',
  'bool',
  'complex',
  'datetime',
  'experimentState',
  'file',
  'fileSet',
  'float',
  'floatSeries',
  'gitRef',
  'imageSeries',
  'int',
  'notebookRef',
  'string',
  'stringSeries',
  'stringSet',
  'notSupported',
] as const;

export type AttributeType = ArrayElementType<typeof availableAttributeTypes>;

export type ArtifactAttribute = BaseAttribute & {
  attributeType: 'artifact';
  hash: string;
};

export type BaseAttribute = {
  attributeName: string;
  attributeType: AttributeType;
};

export type BooleanAttribute = BaseAttribute & {
  attributeType: 'bool';
  value: boolean;
};

export type DateTimeAttribute = BaseAttribute & {
  attributeType: 'datetime';
  value: Date;
};

export type ExperimentStateAttribute = BaseAttribute & {
  attributeType: 'experimentState';
  value: ExperimentState;
};

export type FileAttribute = BaseAttribute & {
  attributeType: 'file';
  ext: string;
  name: string;
  size: number;
};

export type FileSetAttribute = BaseAttribute & {
  attributeType: 'fileSet';
  size: number;
};

export type FloatAttribute = BaseAttribute & {
  attributeType: 'float';
  value: number;
};

export type FloatSeriesAttribute = BaseAttribute & {
  attributeType: 'floatSeries';
  average?: number;
  config: FloatSeriesAttributeConfig;
  last?: number;
  lastStep?: number;
  max?: number;
  min?: number;
  variance?: number;
};

export type GitRefAttribute = BaseAttribute & {
  attributeType: 'gitRef';
  commit: GitCommit;
  currentBranch?: string;
  remotes?: string[];
  repositoryDirty: boolean;
};

export type ImageSeriesAttribute = BaseAttribute & {
  attributeType: 'imageSeries';
  channelId: string;
  lastStep?: number;
};

export type IntegerAttribute = BaseAttribute & {
  attributeType: 'int';
  value: number;
};

export type NotebookRefAttribute = BaseAttribute & {
  attributeType: 'notebookRef';
  checkpointCreated?: Date;
  checkpointDeleted?: boolean;
  checkpointId?: string;
  checkpointName?: string;
  notebookDeleted: boolean;
  notebookId: string;
  notebookName?: string;
};

export type ComplexAttribute = BaseAttribute & {
  attributeType: 'complex';
  __type: {
    name: string;
  };
  __content: string;
};

export type StringAttribute = BaseAttribute & {
  attributeType: 'string';
  value: string;
};

export type StringSeriesAttribute = BaseAttribute & {
  attributeType: 'stringSeries';
  last?: string;
  lastStep?: number;
};

export type StringSetAttribute = BaseAttribute & {
  attributeType: 'stringSet';
  values: string[];
};

export type NotSupportedAttribute = BaseAttribute & {
  attributeType: 'notSupported';
};

export type Attribute =
  | ArtifactAttribute
  | BooleanAttribute
  | DateTimeAttribute
  | ExperimentStateAttribute
  | FileAttribute
  | FileSetAttribute
  | FloatAttribute
  | FloatSeriesAttribute
  | GitRefAttribute
  | ImageSeriesAttribute
  | IntegerAttribute
  | NotebookRefAttribute
  | ComplexAttribute
  | StringAttribute
  | StringSeriesAttribute
  | StringSetAttribute
  | NotSupportedAttribute;

export type ExperimentState = 'running' | 'idle';

export type FloatSeriesAttributeConfig = {
  max?: number;
  min?: number;
  unit?: string;
};

export type GitCommit = {
  authorEmail: string;
  authorName: string;
  commitDate: Date;
  commitId: string;
  message: string;
};

export type AttributeByType<T> = T extends 'artifact'
  ? ArtifactAttribute
  : T extends 'bool'
    ? BooleanAttribute
    : T extends 'datetime'
      ? DateTimeAttribute
      : T extends 'experimentState'
        ? ExperimentStateAttribute
        : T extends 'file'
          ? FileAttribute
          : T extends 'fileSet'
            ? FileSetAttribute
            : T extends 'float'
              ? FloatAttribute
              : T extends 'floatSeries'
                ? FloatSeriesAttribute
                : T extends 'gitRef'
                  ? GitRefAttribute
                  : T extends 'imageSeries'
                    ? ImageSeriesAttribute
                    : T extends 'int'
                      ? IntegerAttribute
                      : T extends 'notebookRef'
                        ? NotebookRefAttribute
                        : T extends 'complex'
                          ? ComplexAttribute
                          : T extends 'string'
                            ? StringAttribute
                            : T extends 'stringSeries'
                              ? StringSeriesAttribute
                              : T extends 'stringSet'
                                ? StringSetAttribute
                                : T extends 'notSupported'
                                  ? NotSupportedAttribute
                                  : never;
