import React from 'react';
import { GlobalFullscreenContext } from '@neptune/shared/common-domain';

type UseFullscreenExpandAnimationProps = {
  fullscreen: boolean;
  elementRef: React.RefObject<HTMLElement | null>;
  zIndex?: number;
};

type CssPosition = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

const fullscreenPosition: CssPosition = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const fullscreenActiveDefaultZIndex = 3900; // less than modal overlay

/**
  Handles inset position and position itself to corresponding values when fullscreen switches.
  @Note Remember to add transition CSS to the element, e.g.:
    ```
    transition:
      top 200ms ease 0ms,
      right 200ms ease 0ms,
      bottom 200ms ease 0ms,
      left 200ms ease 0ms;
    ```
 */
export const useFullscreenExpandAnimation = ({
  fullscreen,
  elementRef,
  zIndex = fullscreenActiveDefaultZIndex,
}: UseFullscreenExpandAnimationProps) => {
  const storedStyle = React.useRef<Record<string, string>>();
  const layerPosition = React.useRef<CssPosition>();
  const cssZIndex = `${zIndex}`;
  const { maximize: globalMaximize, restore: globalRestore } =
    React.useContext(GlobalFullscreenContext);

  React.useEffect(() => {
    const element = elementRef.current;

    if (!element) {
      return;
    }

    if (fullscreen) {
      storedStyle.current = storeBaseStyles(element);
      layerPosition.current = getLayerPosition(element);

      setFixedPosition(element, layerPosition.current, cssZIndex);
      // delay is crucial to make transition effect present
      setTimeout(() => setFixedPosition(element, fullscreenPosition, cssZIndex), 50);
      globalMaximize();
    } else {
      if (layerPosition.current) {
        // transition to previous position
        setFixedPosition(element, layerPosition.current, cssZIndex);
      }

      // restore all styles (including `position: fixed`)
      setTimeout(
        () => {
          if (storedStyle.current) {
            restoreBaseStyles(element, storedStyle.current);
            globalRestore();
          }
        },
        layerPosition.current ? 200 : 0,
      );
    }
  }, [fullscreen, elementRef, cssZIndex, globalMaximize, globalRestore]);
};

function getLayerPosition(element: HTMLElement): CssPosition {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top,
    left: rect.left,
    bottom: window.innerHeight - rect.bottom,
    right: window.innerWidth - rect.right,
  };
}

function setFixedPosition(el: HTMLElement, position: CssPosition, zIndex: string) {
  el.style.zIndex = zIndex;
  el.style.position = 'fixed';
  el.style.height = 'auto';
  el.style.width = 'auto';
  // We have to add scrolling capability to the wrapper layer due to tether.
  // Tether is configured to respect and place overlay inside layer with scrolling.
  // However we're changing position to fixed and visually escaping from parent
  // scrolling area, but tether is not aware of this, so we have to create a brand
  // new scrolling area especially for fullscreen to get rid of parent restrictions.
  el.style.overflowY = 'auto';
  el.style.top = `${position.top}px`;
  el.style.left = `${position.left}px`;
  el.style.bottom = `${position.bottom}px`;
  el.style.right = `${position.right}px`;
}

function storeBaseStyles(element: HTMLElement): Record<string, string> {
  const { width, height, zIndex, position, top, left, right, bottom, overflowY } = element.style;
  return { width, height, zIndex, position, top, left, right, bottom, overflowY };
}

function restoreBaseStyles(element: HTMLElement, data: Record<string, string>) {
  element.style.top = data.top;
  element.style.left = data.left;
  element.style.bottom = data.bottom;
  element.style.right = data.right;
  element.style.position = data.position;
  element.style.height = data.height;
  element.style.width = data.width;
  element.style.overflowY = data.overflowY;
  element.style.zIndex = data.zIndex;
}
