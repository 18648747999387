import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { AttributeType } from './attribute-model';

const attributeIcons: Partial<Record<AttributeType, GlyphName>> = {
  artifact: 'file-set',
  bool: 'bool',
  datetime: 'datetime',
  float: 'float',
  floatSeries: 'float-series',
  fileSet: 'file-set',
  imageSeries: 'file-series',
  int: 'integer',
  string: 'string',
  stringSeries: 'string-series',
  stringSet: 'string-set',
  file: 'file',
  gitRef: 'github',
  notebookRef: 'notebook',
  experimentState: 'status',
};

const predefinedAttributeIcons: Partial<Record<AttributeType, GlyphName>> = {
  floatSeries: 'curved-dots',
  datetime: 'datetime',
};

export const getAttributeGlyphName = (attribute: AttributeType): GlyphName => {
  return attributeIcons[attribute] || 'circle';
};

export const getPredefinedAttributeGlyphName = (attribute: AttributeType): GlyphName => {
  return predefinedAttributeIcons[attribute] || 'circle';
};
