import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';
import { Merge } from 'common/utility-types';

import { selectContentBeforeWrap } from '../selectContentBeforeWrap';
import { InputVariant } from '../types';

import './Input.less';

export type InputProps = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  {
    className?: string;
    disabled?: boolean;
    hideArrows?: boolean;
    error?: boolean;
    selectOnFocus?: boolean;
    variant?: InputVariant;
  }
>;

const block = bemBlock('neptune-input');

export const Input: React.FC<InputProps> = ({
  className,
  disabled,
  hideArrows,
  error,
  selectOnFocus,
  variant = 'medium',
  onFocus,
  ...props
}) => {
  const classNames = block({
    modifiers: {
      disabled,
      'hide-arrows': hideArrows,
      error,
      variant,
    },
    extra: className,
  });

  return (
    <input
      {...props}
      className={classNames}
      disabled={disabled}
      onFocus={selectOnFocus ? selectContentBeforeWrap(onFocus) : onFocus}
      data-is-invalid={error}
    />
  );
};
