// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';

// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getWidthStyle } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import TableBody from 'neptune-core-ui/components/table/TableBody';
// eslint-disable-next-line no-restricted-imports
import TableHead from 'neptune-core-ui/components/table/TableHead';
// eslint-disable-next-line no-restricted-imports
import TableFoot from 'neptune-core-ui/components/table/TableFoot';


// Module
import './Table.less';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Definition of columns used to render the table. */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /** Number will be converted to pixels (10 -> 10px) */
      width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),

      /** className for the col */
      className: PropTypes.string,

      /**
       * Head cell definition is an object (see [TableHeadCell](#tableheadcell)):
       *
       * - `actions`
       * - `align`
       * - `className`
       * - `colSpan`
       * - `data`
       * - `dataKey`
       * - `renderer`
       * - `renderParams`
       * - `rowSpan`
       * - `width`
       * - `onClick`
       */
      head: PropTypes.shape({
        actions: PropTypes.shape({
          remove: PropTypes.object,
          reorder: PropTypes.object,
          resize: PropTypes.object,
          sort: PropTypes.object,
        }),
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        colSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        data: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.object,
          PropTypes.array,
        ]),
        dataKey: PropTypes.string,
        renderer: PropTypes.func,
        renderParams: PropTypes.any,
        rowSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        width: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        onClick: ncuiPropTypes.eventHandler,
      }),

      /**
       * Body cell definition is an object (see [TableCell](#tablecell)):
       *
       * - `align`
       * - `className`
       * - `colSpan`
       * - `dataKey`
       * - `header`
       * - `renderer`
       * - `renderParams`
       * - `width`
       * - `onClick`
       */
      body: PropTypes.shape({
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        colSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        dataKey: PropTypes.string,
        header: PropTypes.bool,
        renderer: PropTypes.func,
        renderParams: PropTypes.any,
        width: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        onClick: ncuiPropTypes.eventHandler,
      }),

      /**
       * Foot cell definition is an object (see [TableCell](#tablecell)):
       *
       * - `align`
       * - `className`
       * - `colSpan`
       * - `data`
       * - `dataKey`
       * - `header`
       * - `renderer`
       * - `renderParams`
       * - `rowSpan`
       * - `width`
       * - `onClick`
       */
      foot: PropTypes.shape({
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        colSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        data: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.object,
          PropTypes.array,
        ]),
        dataKey: PropTypes.string,
        header: PropTypes.bool,
        renderer: PropTypes.func,
        renderParams: PropTypes.any,
        rowSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        width: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        onClick: ncuiPropTypes.eventHandler,
      }),
    }),
  ),

  /**
   * Collection of objects or arrays of simple values
   * (see [TableBody](#tablebody)).
   */
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ])),

  /**
   * Definition of groups used to render additional header above the table columns
   */
  colGroups: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      colSpan: PropTypes.number,
      renderer: PropTypes.func,
    }),
  ),

  /** Applicable for generated TableBody only */
  hoverableRows: PropTypes.bool,

  /** Determines which parts of the table will be rendered */
  renderParts: PropTypes.arrayOf(
    PropTypes.oneOf(['head', 'body', 'foot']),
  ),

  /** Applicable for generated TableBody only */
  rowClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),

  /** function to calculate row key based on rowData and index, (rowData, index) => key */
  getRowKey: PropTypes.func,

  tableRef: PropTypes.func,

  theme: PropTypes.oneOf(['blue', 'grey']),

  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `params` -> object
   *   - `target` -> reference column
   *   - `source` -> column that has been moved
   *   - `order` -> one of ['before', 'after']
   *   - `payload` -> `onOrderChange.payload`
   */
  onOrderChange: ncuiPropTypes.eventHandler,

  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event` -> [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` -> object
   *   - `column` -> value of `column` property
   *   - `payload` -> `onRemove.payload`
   */
  onRemove: ncuiPropTypes.eventHandler,

  /** onClick event handler for generated rows (see [TableRow](#tablerow)) */
  onRowClick: ncuiPropTypes.eventHandler,

  /** onMouseOut event handler for generated rows (see [TableRow](#tablerow)) */
  onRowMouseOut: ncuiPropTypes.eventHandler,

  /** onMouseOver event handler for generated rows (see [TableRow](#tablerow)) */
  onRowMouseOver: ncuiPropTypes.eventHandler,

  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler function
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with argument:
   *
   * - `params` -> object with the following properties:
   *   - `sortBy`
   *   - `sortOrder` -> new sort order
   *   - `prevSortOrder` -> previous sort order
   *   - `payload` -> value of `onSortChange.payload`
   */
  onSortChange: ncuiPropTypes.eventHandler,

  dataRole: PropTypes.string,

  withBorderRadius: PropTypes.bool,
};


const defaultProps = {
  theme: 'blue',
  renderParts: ['head', 'body', 'foot'],
  withBorderRadius: true,
};


const Table = ({
  children,
  className,
  columns,
  data,
  colGroups,
  hoverableRows,
  renderParts,
  rowClassName,
  getRowKey,
  tableRef,
  width,
  onOrderChange,
  onRemove,
  onRowClick,
  onRowMouseOut,
  onRowMouseOver,
  onSortChange,
  withBorderRadius,
  'data-role': dataRole = 'table',
}) => {

  const cssClasses = classNames(
    'n-table',
    className,
    withBorderRadius && 'n-table__with-border-radius',
  );

  const tableStyle = {...getWidthStyle(width)};

  let colgroupNode = null;
  let headNode = null;
  let footNode = null;
  let bodyNode = null;

  if (columns) {
    const tableToRender = decomposeColumns(
      columns,
      getTableStub(renderParts),
    );

    colgroupNode = getColgroupNode(tableToRender.colgroup);
    headNode = getHeadNode(
      bindTableActions(tableToRender.head, {
        onOrderChange,
        onRemove,
        onSortChange,
      }),
      colGroups,
    );
    footNode = getFootNode(tableToRender.foot);
    bodyNode = getBodyNode(
      tableToRender.body,
      data,
      {
        hoverableRows,
        rowClassName,
        getRowKey,
        onRowClick,
        onRowMouseOut,
        onRowMouseOver,
      },
    );
  }

  return (
    <table
      ref={tableRef}
      className={cssClasses}
      style={tableStyle}
      data-role={dataRole}
    >
      {colgroupNode}
      {headNode}
      {footNode}
      {bodyNode}
      {children}
    </table>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;


function bindTableActions(head, tableActions) {
  if (!head) {
    return;
  }

  const {
    onOrderChange,
    onRemove,
    onSortChange,
  } = tableActions;

  return head.map((cell) => {
    if (cell.actions) {
      if (cell.actions.sort && !cell.actions.sort.onSortChange) {
        cell.actions.sort.onSortChange = onSortChange;
      }
      if (cell.actions.remove && !cell.actions.remove.handleRemove) {
        cell.actions.remove.onRemove = onRemove;
      }
      if (cell.actions.reorder && !cell.actions.reorder.onOrderChange) {
        cell.actions.reorder.onOrderChange = onOrderChange;
      }
    }

    return cell;
  });
}


/**
 * Table stub is used to decompose columns definition.
 * It is based on renderParts
 */
function getTableStub(renderParts) {
  return renderParts.reduce(
    (tableStub, partName) => {
      tableStub[partName] = [];
      return tableStub;
    },
    {
      colgroup: [],
    },
  );
}


/**
 * Decompose columns to proper structures for TableHead, TableBody
 * and TableFoot components
 */
function decomposeColumns(columns, tableStub) {
  if (!columns) {
    return null;
  }

  return columns.reduce((table, column) => {
    table.colgroup.push({
      className: column.className,
      colWidth: getWidthStyle(column.width),
      style: column.style,
    });
    if (table.head) {
      table.head.push(column.head || {});
    }
    if (table.body) {
      table.body.push(column.body || {});
    }
    if (table.foot) {
      table.foot.push(column.foot || {});
    }

    return table;
  }, tableStub);
}


function getColgroupNode(colgroup) {
  return (
    <colgroup>
    {
      colgroup.map(({ className, colWidth, style }, index) => (
        <col
          key={index}
          style={{...style, ...colWidth}}
          className={className}
        />
      ))
    }
    </colgroup>
  );
}


function getHeadNode(head, colGroups) {
  const rows = [];

  if (colGroups) {
    rows.push(colGroups);
  }

  if (head) {
    rows.push(head);
  }

  if (rows.length > 0) {
    return (<TableHead rows={rows} />);
  }
}


function getFootNode(foot) {
  if (foot) {
    return (<TableFoot rows={[foot]} />);
  }
}


function getBodyNode(body, data, bodyProps) {
  if (!body || !data) {
    return null;
  }

  return (
    <TableBody
      columns={body}
      data={data}
      {...bodyProps}
    />
  );
}


export default Table;
