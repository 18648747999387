/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentLeaderDTO,
    ExperimentLeaderDTOFromJSON,
    ExperimentLeaderDTOFromJSONTyped,
    ExperimentLeaderDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExperimentLeadersSearchResultDTO
 */
export interface ExperimentLeadersSearchResultDTO {
    /**
     * Map of requested run identifiers to their leaders.
     * @type {{ [key: string]: ExperimentLeaderDTO; }}
     * @memberof ExperimentLeadersSearchResultDTO
     */
    leadersMap: { [key: string]: ExperimentLeaderDTO; };
}

export function ExperimentLeadersSearchResultDTOFromJSON(json: any): ExperimentLeadersSearchResultDTO {
    return ExperimentLeadersSearchResultDTOFromJSONTyped(json, false);
}

export function ExperimentLeadersSearchResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentLeadersSearchResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'leadersMap': (mapValues(json['leadersMap'], ExperimentLeaderDTOFromJSON)),
    };
}

export function ExperimentLeadersSearchResultDTOToJSON(value?: ExperimentLeadersSearchResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'leadersMap': (mapValues(value.leadersMap, ExperimentLeaderDTOToJSON)),
    };
}


