// Libs
import React from 'react';
import { isFunction } from 'lodash';

// App

// Module
export type UseAsyncWrapperParams<ParamsType, ResultType, ErrorType> = {
  errorFormatter?: (error: any) => ErrorType;
  resolver: (params: ParamsType) => Promise<ResultType>;
};

export type UseAsyncWrapperReturns<ParamsType = void, ResultType = void, ErrorType = any> = {
  loading: boolean;
  initiallyLoaded: boolean;
  result?: ResultType;
  error?: ErrorType;
  resolve: (params: ParamsType) => Promise<ResultType | undefined>;
};

export function useAsyncWrapper<T, E = any, ParamsType = void>({
  resolver,
  errorFormatter,
}: UseAsyncWrapperParams<ParamsType, T, E>): UseAsyncWrapperReturns<ParamsType, T, E> {
  const [value, setValue] = React.useState<T>();
  const [error, setError] = React.useState<E>();
  const [loading, setLoading] = React.useState(false);
  const [initiallyLoaded, setInitiallyLoaded] = React.useState(false);
  const currentFetchId = React.useRef(0);

  const resolve = React.useCallback(
    async (params: ParamsType) => {
      const fetchId = ++currentFetchId.current;
      setError(undefined);
      setLoading(true);

      try {
        const value = await resolver(params);

        if (fetchId !== currentFetchId.current) {
          return; // The result is already stale, drop it.
        }

        setValue(value);
        setLoading(false);
        setInitiallyLoaded(true);

        return value;
      } catch (e) {
        if (fetchId !== currentFetchId.current) {
          return; // The error occured in stale request, ignore it.
        }

        const err = isFunction(errorFormatter) ? errorFormatter(e) : e;
        setError(err as E);
        setLoading(false);
      }
    },
    [resolver, setError, setValue, setLoading, errorFormatter],
  );

  return {
    loading,
    result: value,
    error,
    resolve,
    initiallyLoaded,
  };
}
