import React from 'react';

import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { Text } from '../text/Text';
import { LayoutRow } from '../layout-row/LayoutRow';

import { useHorizontalTabsContext } from './HorizontalTabsContext';

import './HorizontalTabsItem.less';

const block = bemBlock('n-horizontal-tabs-item');

type HorizontalTabsItemProps = {
  disabled?: boolean;
  tabName: string;
  'data-role'?: string;
  glyph?: GlyphName;
};

export const HorizontalTabsItem: React.FC<HorizontalTabsItemProps> = ({
  disabled,
  tabName,
  'data-role': dataRole,
  glyph,
  children,
}) => {
  const { activeTabName, onChange, tabNamesInOrder } = useHorizontalTabsContext();

  const active = activeTabName === tabName;

  const rightSiblingOfActiveTab = React.useMemo(() => {
    const activeTabIndex = tabNamesInOrder.indexOf(activeTabName);
    const currentTabIndex = tabNamesInOrder.indexOf(tabName);

    return currentTabIndex - activeTabIndex === 1;
  }, [activeTabName, tabName, tabNamesInOrder]);

  const firstTab = React.useMemo(() => {
    const currentTabIndex = tabNamesInOrder.indexOf(tabName);
    return currentTabIndex === 0;
  }, [tabName, tabNamesInOrder]);

  const handleChange = React.useCallback(() => {
    if (active) {
      return;
    }

    onChange?.(tabName);
  }, [active, onChange, tabName]);

  return (
    <button
      data-role={dataRole}
      disabled={disabled}
      className={block({
        modifiers: {
          active,
          'right-sibling-of-active-tab': rightSiblingOfActiveTab,
          'first-tab': firstTab,
        },
      })}
      onClick={handleChange}
      data-tab-item={tabName}
      data-selected-tab={active}
    >
      <LayoutRow spacedChildren="sm" alignContent="center" alignItems="center">
        {glyph && (
          <Icon
            glyph={glyph}
            size="1x"
            className={block({ element: 'icon', modifiers: { active } })}
          />
        )}

        {children && active && (
          <Text size="sm" fontWeight="semibold" color="text" ellipsis className={block('text')}>
            {children}
          </Text>
        )}
      </LayoutRow>
    </button>
  );
};
