import React from 'react';
import { noop } from 'lodash';

import { bemBlock } from '../../modules/bem';
import { LayoutRow } from '../layout-row/LayoutRow';
import { LayoutColumn } from '../layout-column/LayoutColumn';

import { HorizontalTabsItem } from './HorizontalTabsItem';
import { HorizontalTabsContext } from './HorizontalTabsContext';

import './HorizontalTabs.less';

const block = bemBlock('n-horizontal-tabs');

type HorizontalTabsProps = {
  children: React.ReactNode;
  activeTabName?: string;
  className?: string;
  onChange?: (tabName: string) => void;
  startOutlet?: React.ReactNode;
  endOutlet?: React.ReactNode;
  'data-role'?: string;
};

const HorizontalTabsRaw: React.FC<HorizontalTabsProps> = ({
  children,
  activeTabName = '',
  className,
  onChange = noop,
  startOutlet,
  endOutlet,
  'data-role': dataRole,
}) => {
  const tabNamesInOrder = React.useMemo(() => extractTabNames(children), [children]);

  return (
    <LayoutColumn
      className={block({ element: 'container', extra: className })}
      data-role={dataRole}
    >
      <HorizontalTabsContext.Provider value={{ activeTabName, onChange, tabNamesInOrder }}>
        <LayoutRow>
          {startOutlet}

          <LayoutRow className={block('tabs')}>{children}</LayoutRow>

          {endOutlet}
        </LayoutRow>
      </HorizontalTabsContext.Provider>

      <div className={block('bottom-border')} />
    </LayoutColumn>
  );
};

export const HorizontalTabs = Object.assign(HorizontalTabsRaw, {
  Item: HorizontalTabsItem,
});

function extractTabNames(children: React.ReactNode): string[] {
  let tabNames: string[] = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === HorizontalTabsItem) {
        tabNames.push(child.props.tabName);
      } else if (child.props.children) {
        tabNames = tabNames.concat(extractTabNames(child.props.children));
      }
    }
  });

  return tabNames;
}
