// Libs
import React from 'react';
import TetherComponent from 'react-tether';
import Tether from 'tether';
import { uniq, uniqueId } from 'lodash';

import { bemBlock } from '../../modules/bem';
import { getZIndex } from '../../modules/z-index';

// Module
import './DropdownTether.less';

export interface IOnOutsideClick {
  (event: MouseEvent, collapse?: () => void): void;
}

type AutoWidthMenu = 'none' | 'minWidth' | 'width';

type YPlacement = 'top' | 'middle' | 'bottom';
type XPlacement = 'left' | 'center' | 'right';
export type TetherAttachmentVariant = 'auto auto' | `${YPlacement} ${XPlacement}`;

export type DropdownTetherProps = Tether.ITetherOptions & {
  /**
   * A string of the form '<vert-attachment> <horiz-attachment>'
   * Please see http://tetherjs.dev/#options
   */
  attachment: TetherAttachmentVariant;
  /**
   * A string of the form '<vert-attachment> <horiz-attachment>'
   * Please see http://tetherjs.dev/#options
   */
  targetAttachment: TetherAttachmentVariant;
  /**
   * An array of constraint definition objects
   * For documentation see http://tetherjs.dev/#options
   * For examples please visit: http://tetherjs.dev/#constraints
   */
  // constraints: Tether.ITetherConstraint[]
  /**
   * Sets minWidth or width of Menu to the value of Toggle's width
   */
  autoWidthMenu?: AutoWidthMenu;
  onTargetClick?: (event: MouseEvent) => void;
  onElementClick?: (event: MouseEvent) => void;
  onElementKeyDown?: (event: KeyboardEvent) => void;
  onElementKeyUp?: (event: KeyboardEvent) => void;
  onOutsideClick?: IOnOutsideClick;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  zIndex?: number;
  elementRef?: React.MutableRefObject<HackedTether | null>;
};

export type HackedTether = Omit<TetherComponent, 'getTetherInstance'> & {
  getTetherInstance: () => Tether & Tether.ITetherOptions;
};
type RefHandler = (id: string, ref: HackedTether) => void;
const TetherRefContext = React.createContext<RefHandler | undefined>(undefined);

const block = bemBlock('n-DropdownTether');

export class DropdownTether extends React.Component<DropdownTetherProps> {
  public static defaultProps = {
    autoWidthMenu: 'minWidth',
    attachment: 'top left',
    targetAttachment: 'bottom left',
    constraints: [{ to: 'scrollParent', pin: true }],
  };

  tetherRef: HackedTether | null = null;

  toggle?: HTMLElement;

  tetherRefs: Record<string, HackedTether> = {};

  id = uniqueId('dropdown-tether-');

  declare context: React.ContextType<typeof TetherRefContext>;

  static contextType = TetherRefContext;

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, true);

    if (this.props.onFocus) {
      document.addEventListener('focusin', this.handleFocusIn, true);
    }

    if (this.props.onBlur) {
      document.addEventListener('focusout', this.handleFocusOut, true);
    }

    if (this.props.onElementKeyDown) {
      document.addEventListener('keydown', this.handleElementKeyDown, true);
    }

    if (this.props.onElementKeyUp) {
      document.addEventListener('keyup', this.handleElementKeyUp, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
    document.removeEventListener('focusin', this.handleFocusIn, true);
    document.removeEventListener('focusout', this.handleFocusOut, true);
    document.removeEventListener('keydown', this.handleElementKeyDown, true);
    document.removeEventListener('keyup', this.handleElementKeyUp, true);
  }

  setTetherRef = (ref: HackedTether) => {
    if (this.context) {
      this.context(this.id, ref);
    }

    if (this.props.elementRef !== undefined) {
      this.props.elementRef.current = ref;
    }

    this.tetherRef = ref;
  };

  getTetherInstance() {
    if (!this.tetherRef) {
      return null;
    }

    return this.tetherRef.getTetherInstance();
  }

  handleFocusIn = (ev: FocusEvent): void => {
    const { onFocus } = this.props;

    if (!onFocus) {
      return;
    }

    const tetherInstance = this.getTetherInstance();

    if (!tetherInstance) {
      if (this.toggle && this.toggle.contains(ev.target as HTMLElement)) {
        onFocus();
      }

      return;
    }

    if (this.dropdownInstanceContains(ev.target)) {
      onFocus();
    }
  };

  handleFocusOut = (ev: FocusEvent): void => {
    const { onBlur } = this.props;

    if (!onBlur) {
      return;
    }

    const tetherInstance = this.getTetherInstance();

    if (!tetherInstance) {
      if (this.toggle && this.toggle.contains(ev.target as HTMLElement)) {
        onBlur();
      }

      return;
    }

    if (this.dropdownInstanceContains(ev.target)) {
      if (!this.dropdownInstanceContains(ev.relatedTarget)) {
        onBlur();
      }
    }
  };

  dropdownInstanceContains(element: React.ReactNode) {
    const tetherInstance = this.getTetherInstance();

    return (
      (tetherInstance?.element && tetherInstance.element.contains(element)) ||
      (tetherInstance?.target && tetherInstance.target.contains(element))
    );
  }

  handleDocumentClick = (ev: MouseEvent): void => {
    const { onElementClick, onOutsideClick, onTargetClick } = this.props;

    const tetherInstance = this.getTetherInstance();

    if (!tetherInstance || (!onElementClick && !onTargetClick && !onOutsideClick)) {
      return;
    }

    if (
      (tetherInstance?.element && tetherInstance?.element.contains(ev.target)) ||
      this.isClickedOnTetheredChildren(ev)
    ) {
      if (onElementClick) {
        onElementClick(ev);
      }
    } else if (tetherInstance?.target && tetherInstance.target.contains(ev.target)) {
      if (onTargetClick) {
        onTargetClick(ev);
      }
    } else {
      if (onOutsideClick) {
        onOutsideClick(ev);
      }
    }
  };

  handleElementKeyDown = (ev: KeyboardEvent): void => {
    const tetherInstance = this.getTetherInstance();
    const { onElementKeyDown } = this.props;

    if (onElementKeyDown && tetherInstance?.element?.contains(ev.target)) {
      onElementKeyDown(ev);
    }
  };

  handleElementKeyUp = (ev: KeyboardEvent): void => {
    const tetherInstance = this.getTetherInstance();
    const { onElementKeyUp } = this.props;

    if (onElementKeyUp && tetherInstance?.element?.contains(ev.target)) {
      onElementKeyUp(ev);
    }
  };

  isClickedOnTetheredChildren = (ev: MouseEvent) => {
    const childElements = uniq(
      Object.values(this.tetherRefs)
        .map((tetherComponent) => tetherComponent && tetherComponent.getTetherInstance())
        .filter((instance) => instance != null)
        .map((instance) => [instance.element, instance.target])
        .flat()
        .filter((element) => element != null),
    );

    return childElements.some((element) => element.contains(ev.target));
  };

  getMenuStyle(initialStyle: React.CSSProperties = {}) {
    const { autoWidthMenu } = this.props;

    if (!this.toggle) {
      return { style: initialStyle };
    }

    if (!autoWidthMenu || autoWidthMenu === 'none') {
      return { style: initialStyle };
    }

    return {
      style: {
        [autoWidthMenu]: `${this.toggle.offsetWidth}px`,
        ...initialStyle,
      },
    };
  }

  handleTetherRefs = (id: string, ref: HackedTether) => {
    if (ref == null) {
      delete this.tetherRefs[id];
    } else {
      this.tetherRefs[id] = ref;
    }

    if (this.context) {
      this.context(id, ref);
    }
  };

  render() {
    const { children, className, autoWidthMenu, ...tetherProps } = this.props;
    const [toggleElement, menuElement] = React.Children.toArray(children);

    let toggle = toggleElement;

    if (React.isValidElement(toggleElement)) {
      toggle = React.cloneElement(toggleElement as any, {
        elementRef: (el: HTMLElement) => {
          const toggleElementRef = toggleElement.props.elementRef;
          this.toggle = el;

          if (toggleElementRef) {
            if (typeof toggleElementRef == 'object') {
              toggleElementRef.current = el;
            }

            if (typeof toggleElementRef == 'function') {
              toggleElementRef(el);
            }
          }
        },
      });
    }

    let menu = menuElement;

    if (React.isValidElement(menuElement)) {
      menu = React.cloneElement(menuElement as any, {
        tabIndex: 1,
        ...this.getMenuStyle(menuElement.props.style),
      });
    }

    return (
      <TetherRefContext.Provider value={this.handleTetherRefs}>
        <TetherComponent
          ref={this.setTetherRef}
          className={block({ extra: className })}
          style={{ zIndex: this.props.zIndex ?? (this.toggle && getZIndex(this.toggle)) }}
          {...tetherProps}
        >
          {toggle}
          {menu}
        </TetherComponent>
      </TetherRefContext.Provider>
    );
  }
}
