/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExperimentLeadersSearchDTO
 */
export interface ExperimentLeadersSearchDTO {
    /**
     * Identifier of the project. Semantically - so-called \'project identifier\', so either UUID or a fully-qualified name
     * @type {string}
     * @memberof ExperimentLeadersSearchDTO
     */
    projectIdentifier: string;
    /**
     * List of run identifiers for which the experiment leaders should be returned. Semantically - so-called \'experiment identifier\', so either UUID or a fully-qualified name
     * @type {Array<string>}
     * @memberof ExperimentLeadersSearchDTO
     */
    runIdentifiers: Array<string>;
}

export function ExperimentLeadersSearchDTOFromJSON(json: any): ExperimentLeadersSearchDTO {
    return ExperimentLeadersSearchDTOFromJSONTyped(json, false);
}

export function ExperimentLeadersSearchDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentLeadersSearchDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'runIdentifiers': json['runIdentifiers'],
    };
}

export function ExperimentLeadersSearchDTOToJSON(value?: ExperimentLeadersSearchDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectIdentifier': value.projectIdentifier,
        'runIdentifiers': value.runIdentifiers,
    };
}


