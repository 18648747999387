/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardGroupParamsDTO,
    LeaderboardGroupParamsDTOFromJSON,
    LeaderboardGroupParamsDTOFromJSONTyped,
    LeaderboardGroupParamsDTOToJSON,
    LeaderboardSortParamsDTO,
    LeaderboardSortParamsDTOFromJSON,
    LeaderboardSortParamsDTOFromJSONTyped,
    LeaderboardSortParamsDTOToJSON,
    LeaderboardViewColumnListDTO,
    LeaderboardViewColumnListDTOFromJSON,
    LeaderboardViewColumnListDTOFromJSONTyped,
    LeaderboardViewColumnListDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface TableViewParamsDTO
 */
export interface TableViewParamsDTO {
    /**
     * 
     * @type {LeaderboardViewColumnListDTO}
     * @memberof TableViewParamsDTO
     */
    columnList: LeaderboardViewColumnListDTO;
    /**
     * 
     * @type {boolean}
     * @memberof TableViewParamsDTO
     */
    experimentsOnly?: boolean;
    /**
     * 
     * @type {LeaderboardGroupParamsDTO}
     * @memberof TableViewParamsDTO
     */
    groupOptions?: LeaderboardGroupParamsDTO;
    /**
     * 
     * @type {string}
     * @memberof TableViewParamsDTO
     */
    query?: string;
    /**
     * 
     * @type {LeaderboardSortParamsDTO}
     * @memberof TableViewParamsDTO
     */
    sortOptions: LeaderboardSortParamsDTO;
}

export function TableViewParamsDTOFromJSON(json: any): TableViewParamsDTO {
    return TableViewParamsDTOFromJSONTyped(json, false);
}

export function TableViewParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TableViewParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOFromJSON(json['columnList']),
        'experimentsOnly': !exists(json, 'experimentsOnly') ? undefined : json['experimentsOnly'],
        'groupOptions': !exists(json, 'groupOptions') ? undefined : LeaderboardGroupParamsDTOFromJSON(json['groupOptions']),
        'query': !exists(json, 'query') ? undefined : json['query'],
        'sortOptions': LeaderboardSortParamsDTOFromJSON(json['sortOptions']),
    };
}

export function TableViewParamsDTOToJSON(value?: TableViewParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOToJSON(value.columnList),
        'experimentsOnly': value.experimentsOnly,
        'groupOptions': LeaderboardGroupParamsDTOToJSON(value.groupOptions),
        'query': value.query,
        'sortOptions': LeaderboardSortParamsDTOToJSON(value.sortOptions),
    };
}


