/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Alias,
    AliasFromJSON,
    AliasToJSON,
    AliasParams,
    AliasParamsFromJSON,
    AliasParamsToJSON,
    ArtifactAttributeDTO,
    ArtifactAttributeDTOFromJSON,
    ArtifactAttributeDTOToJSON,
    AttributeQueryDTO,
    AttributeQueryDTOFromJSON,
    AttributeQueryDTOToJSON,
    AttributeStyleSettingsParamsDTO,
    AttributeStyleSettingsParamsDTOFromJSON,
    AttributeStyleSettingsParamsDTOToJSON,
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOToJSON,
    AttributesDTO,
    AttributesDTOFromJSON,
    AttributesDTOToJSON,
    AttributesRequestsListDTO,
    AttributesRequestsListDTOFromJSON,
    AttributesRequestsListDTOToJSON,
    AttributesSearchResultDTO,
    AttributesSearchResultDTOFromJSON,
    AttributesSearchResultDTOToJSON,
    AttributesStyleSettingsResultDTO,
    AttributesStyleSettingsResultDTOFromJSON,
    AttributesStyleSettingsResultDTOToJSON,
    AuditInfo,
    AuditInfoFromJSON,
    AuditInfoToJSON,
    BoolAttributeDTO,
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOToJSON,
    BulkOpResultDTO,
    BulkOpResultDTOFromJSON,
    BulkOpResultDTOToJSON,
    CheckpointDTO,
    CheckpointDTOFromJSON,
    CheckpointDTOToJSON,
    CheckpointListDTO,
    CheckpointListDTOFromJSON,
    CheckpointListDTOToJSON,
    CheckpointUpdateDTO,
    CheckpointUpdateDTOFromJSON,
    CheckpointUpdateDTOToJSON,
    ComplexAttributeDTO,
    ComplexAttributeDTOFromJSON,
    ComplexAttributeDTOToJSON,
    DashboardDTO,
    DashboardDTOFromJSON,
    DashboardDTOToJSON,
    DashboardListDTO,
    DashboardListDTOFromJSON,
    DashboardListDTOToJSON,
    DashboardVersionDTO,
    DashboardVersionDTOFromJSON,
    DashboardVersionDTOToJSON,
    DatetimeAttributeDTO,
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOToJSON,
    DownloadPrepareRequest,
    DownloadPrepareRequestFromJSON,
    DownloadPrepareRequestToJSON,
    Experiment,
    ExperimentFromJSON,
    ExperimentToJSON,
    ExperimentAttributesDTO,
    ExperimentAttributesDTOFromJSON,
    ExperimentAttributesDTOToJSON,
    ExperimentCreationParams,
    ExperimentCreationParamsFromJSON,
    ExperimentCreationParamsToJSON,
    ExperimentLeadersSearchDTO,
    ExperimentLeadersSearchDTOFromJSON,
    ExperimentLeadersSearchDTOToJSON,
    ExperimentLeadersSearchResultDTO,
    ExperimentLeadersSearchResultDTOFromJSON,
    ExperimentLeadersSearchResultDTOToJSON,
    ExperimentStateDTO,
    ExperimentStateDTOFromJSON,
    ExperimentStateDTOToJSON,
    ExperimentStats,
    ExperimentStatsFromJSON,
    ExperimentStatsToJSON,
    FileAttributeDTO,
    FileAttributeDTOFromJSON,
    FileAttributeDTOToJSON,
    FileEntry,
    FileEntryFromJSON,
    FileEntryToJSON,
    FloatAttributeDTO,
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOToJSON,
    FloatSeriesAttributeDTO,
    FloatSeriesAttributeDTOFromJSON,
    FloatSeriesAttributeDTOToJSON,
    FloatSeriesValuesDTO,
    FloatSeriesValuesDTOFromJSON,
    FloatSeriesValuesDTOToJSON,
    ImageSeriesValuesDTO,
    ImageSeriesValuesDTOFromJSON,
    ImageSeriesValuesDTOToJSON,
    IntAttributeDTO,
    IntAttributeDTOFromJSON,
    IntAttributeDTOToJSON,
    LeaderboardCsvExportParamsDTO,
    LeaderboardCsvExportParamsDTOFromJSON,
    LeaderboardCsvExportParamsDTOToJSON,
    LeaderboardDTO,
    LeaderboardDTOFromJSON,
    LeaderboardDTOToJSON,
    LeaderboardEntriesSearchResultDTO,
    LeaderboardEntriesSearchResultDTOFromJSON,
    LeaderboardEntriesSearchResultDTOToJSON,
    LeaderboardFieldSuggestionDTO,
    LeaderboardFieldSuggestionDTOFromJSON,
    LeaderboardFieldSuggestionDTOToJSON,
    LeaderboardViewDTO,
    LeaderboardViewDTOFromJSON,
    LeaderboardViewDTOToJSON,
    LeaderboardViewListDTO,
    LeaderboardViewListDTOFromJSON,
    LeaderboardViewListDTOToJSON,
    LeaderboardViewSetDefaultDTO,
    LeaderboardViewSetDefaultDTOFromJSON,
    LeaderboardViewSetDefaultDTOToJSON,
    LeaderboardViewUpdateDTO,
    LeaderboardViewUpdateDTOFromJSON,
    LeaderboardViewUpdateDTOToJSON,
    LimitedChannelValuesDTO,
    LimitedChannelValuesDTOFromJSON,
    LimitedChannelValuesDTOToJSON,
    MultiSearchLeaderboardEntriesParamsDTO,
    MultiSearchLeaderboardEntriesParamsDTOFromJSON,
    MultiSearchLeaderboardEntriesParamsDTOToJSON,
    NewCheckpointDTO,
    NewCheckpointDTOFromJSON,
    NewCheckpointDTOToJSON,
    NewDashboardDTO,
    NewDashboardDTOFromJSON,
    NewDashboardDTOToJSON,
    NewLeaderboardViewDTO,
    NewLeaderboardViewDTOFromJSON,
    NewLeaderboardViewDTOToJSON,
    NewProjectChartDTO,
    NewProjectChartDTOFromJSON,
    NewProjectChartDTOToJSON,
    NewVersionDashboardDTO,
    NewVersionDashboardDTOFromJSON,
    NewVersionDashboardDTOToJSON,
    NotebookDTO,
    NotebookDTOFromJSON,
    NotebookDTOToJSON,
    NotebookListDTO,
    NotebookListDTOFromJSON,
    NotebookListDTOToJSON,
    NotebookUpdateDTO,
    NotebookUpdateDTOFromJSON,
    NotebookUpdateDTOToJSON,
    NotebookWithNoContentDTO,
    NotebookWithNoContentDTOFromJSON,
    NotebookWithNoContentDTOToJSON,
    Operation,
    OperationFromJSON,
    OperationToJSON,
    OperationError,
    OperationErrorFromJSON,
    OperationErrorToJSON,
    OperationErrorsListDTO,
    OperationErrorsListDTOFromJSON,
    OperationErrorsListDTOToJSON,
    OptionalOperationError,
    OptionalOperationErrorFromJSON,
    OptionalOperationErrorToJSON,
    PageDTODashboardVersionDTO,
    PageDTODashboardVersionDTOFromJSON,
    PageDTODashboardVersionDTOToJSON,
    ProjectChartDTO,
    ProjectChartDTOFromJSON,
    ProjectChartDTOToJSON,
    ProjectChartListDTO,
    ProjectChartListDTOFromJSON,
    ProjectChartListDTOToJSON,
    ProjectChartUpdateDTO,
    ProjectChartUpdateDTOFromJSON,
    ProjectChartUpdateDTOToJSON,
    QueryAttributeDefinitionsBodyDTO,
    QueryAttributeDefinitionsBodyDTOFromJSON,
    QueryAttributeDefinitionsBodyDTOToJSON,
    QueryAttributeDefinitionsPrioritizedBodyDTO,
    QueryAttributeDefinitionsPrioritizedBodyDTOFromJSON,
    QueryAttributeDefinitionsPrioritizedBodyDTOToJSON,
    QueryAttributeDefinitionsPrioritizedResultDTO,
    QueryAttributeDefinitionsPrioritizedResultDTOFromJSON,
    QueryAttributeDefinitionsPrioritizedResultDTOToJSON,
    QueryAttributeDefinitionsResultDTO,
    QueryAttributeDefinitionsResultDTOFromJSON,
    QueryAttributeDefinitionsResultDTOToJSON,
    QueryAttributesBodyDTO,
    QueryAttributesBodyDTOFromJSON,
    QueryAttributesBodyDTOToJSON,
    QueryAttributesResultDTO,
    QueryAttributesResultDTOFromJSON,
    QueryAttributesResultDTOToJSON,
    SearchAttributesStyleSettingsParamsDTO,
    SearchAttributesStyleSettingsParamsDTOFromJSON,
    SearchAttributesStyleSettingsParamsDTOToJSON,
    SearchLeaderboardEntriesParamsDTO,
    SearchLeaderboardEntriesParamsDTOFromJSON,
    SearchLeaderboardEntriesParamsDTOToJSON,
    SearchLeaderboardParamsDTO,
    SearchLeaderboardParamsDTOFromJSON,
    SearchLeaderboardParamsDTOToJSON,
    SeriesViewListDTO,
    SeriesViewListDTOFromJSON,
    SeriesViewListDTOToJSON,
    SeriesViewRequestDTO,
    SeriesViewRequestDTOFromJSON,
    SeriesViewRequestDTOToJSON,
    Signal,
    SignalFromJSON,
    SignalToJSON,
    StringAttributeDTO,
    StringAttributeDTOFromJSON,
    StringAttributeDTOToJSON,
    StringSeriesAttributeDTO,
    StringSeriesAttributeDTOFromJSON,
    StringSeriesAttributeDTOToJSON,
    StringSeriesValuesDTO,
    StringSeriesValuesDTOFromJSON,
    StringSeriesValuesDTOToJSON,
    StringSetAttributeDTO,
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOToJSON,
    TagsSearchResultDTO,
    TagsSearchResultDTOFromJSON,
    TagsSearchResultDTOToJSON,
    TimeSeriesViewRequest,
    TimeSeriesViewRequestFromJSON,
    TimeSeriesViewRequestToJSON,
    TimeSeriesViewResponse,
    TimeSeriesViewResponseFromJSON,
    TimeSeriesViewResponseToJSON,
    TrackingDataDTO,
    TrackingDataDTOFromJSON,
    TrackingDataDTOToJSON,
    UpdateDashboardDTO,
    UpdateDashboardDTOFromJSON,
    UpdateDashboardDTOToJSON,
    UpdateDashboardVersionDTO,
    UpdateDashboardVersionDTOFromJSON,
    UpdateDashboardVersionDTOToJSON,
    UpdateTagsParams,
    UpdateTagsParamsFromJSON,
    UpdateTagsParamsToJSON,
    UploadMetadataDTO,
    UploadMetadataDTOFromJSON,
    UploadMetadataDTOToJSON,
} from '../models';

export interface CompareCheckpointsContentRequest {
    sourceCheckpointId: string;
    targetCheckpointId: string;
}

export interface CreateAliasRequest {
    projectIdentifier: string;
    aliasToCreate: AliasParams;
}

export interface CreateCheckpointRequest {
    jupyterPath: string;
    notebookId: string;
    description?: string;
    name?: string;
}

export interface CreateDashboardRequest {
    projectIdentifier: string;
    createDashboard: NewDashboardDTO;
}

export interface CreateEmptyCheckpointRequest {
    notebookId: string;
    checkpoint: NewCheckpointDTO;
}

export interface CreateExperimentRequest {
    experimentCreationParams: ExperimentCreationParams;
    userAgent?: string;
    xNeptuneCliVersion?: string;
    xNeptuneLegacyClient?: boolean;
}

export interface CreateLeaderboardViewRequest {
    projectIdentifier: string;
    createView: NewLeaderboardViewDTO;
}

export interface CreateNewVersionOfDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
    createVersionDashboard: NewVersionDashboardDTO;
}

export interface CreateNotebookRequest {
    projectIdentifier: string;
}

export interface CreateProjectChartRequest {
    projectIdentifier: string;
    chart: NewProjectChartDTO;
}

export interface DeleteAliasRequest {
    aliasId: string;
}

export interface DeleteAllDashboardVersionsRequest {
    branchVersionId: string;
    projectIdentifier: string;
}

export interface DeleteAllExperimentsRequest {
    projectIdentifier: string;
}

export interface DeleteCheckpointRequest {
    id: string;
}

export interface DeleteDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
}

export interface DeleteExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface DeleteLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
}

export interface DeleteNotebookRequest {
    id: string;
}

export interface DeleteProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
}

export interface DownloadRequest {
    id: string;
}

export interface DownloadAttributeRequest {
    attribute: string;
    expectedContentDisposition?: DownloadAttributeExpectedContentDispositionEnum;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    path?: string;
}

export interface ExecuteOperationsRequest {
    operations: Array<Operation>;
    userAgent?: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface ExperimentProjectStatsRequest {
    projectIdentifier: string;
}

export interface ExperimentUserStatsRequest {
    organization: string;
    username: string;
}

export interface FileAtomMultipartUploadFinishRequest {
    attribute: string;
    experimentIdentifier: string;
    uploadId: string;
}

export interface FileAtomMultipartUploadPartRequest {
    attribute: string;
    experimentIdentifier: string;
    uploadId: string;
    uploadPartIdx: number;
}

export interface FileAtomMultipartUploadStartRequest {
    attribute: string;
    experimentIdentifier: string;
    ext: string;
    totalLength: number;
}

export interface FileAtomUploadRequest {
    attribute: string;
    experimentIdentifier: string;
    ext: string;
}

export interface FileSetFileMultipartUploadFinishRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    uploadId: string;
}

export interface FileSetFileMultipartUploadPartRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    uploadId: string;
    uploadPartIdx: number;
}

export interface FileSetFileMultipartUploadStartRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
    totalLength: number;
}

export interface FileSetFileUploadRequest {
    attribute: string;
    experimentIdentifier: string;
    subPath: string;
}

export interface GetAliasRequest {
    aliasId: string;
}

export interface GetArtifactAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetAttributesRequest {
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetAttributesWithPathsFilterRequest {
    holderIdentifier?: string;
    holderType?: string;
    attributeQuery?: AttributeQueryDTO;
}

export interface GetAttributesWithPathsFilterProtoRequest {
    holderIdentifier: string;
    holderType?: string;
    attributeQuery?: AttributeQueryDTO;
}

export interface GetAuditInfoRequest {
    organizationIdentifier: string;
    projectIdentifier?: string;
    username?: string;
}

export interface GetBoolAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetChannelImageRequest {
    channelId: string;
    experimentId: string;
    fileName: string;
}

export interface GetChannelValuesRequest {
    channelId: string;
    limit?: number;
    offset?: number;
}

export interface GetCheckpointRequest {
    id: string;
}

export interface GetCheckpointContentRequest {
    id: string;
    expectedContentDisposition?: GetCheckpointContentExpectedContentDispositionEnum;
}

export interface GetComplexAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
}

export interface GetDatetimeAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetDownloadPrepareRequestRequest {
    id: string;
}

export interface GetExperimentRequest {
    experimentId: string;
}

export interface GetExperimentAttributesRequest {
    experimentId: string;
}

export interface GetFileAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    limit?: number;
    skipToStep?: number;
}

export interface GetFloatSeriesValuesCSVRequest {
    attribute: string;
    expectedContentDisposition?: GetFloatSeriesValuesCSVExpectedContentDispositionEnum;
    experimentId?: string;
    gzipped?: boolean;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetFloatSeriesValuesProtoRequest {
    attribute: string;
    experimentId?: string;
    limit?: number;
    skipToStep?: number;
}

export interface GetImageSeriesValueRequest {
    attribute: string;
    index: number;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetImageSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    limit?: number;
    offset?: number;
}

export interface GetImageSeriesValuesZipRequest {
    attribute: string;
    expectedContentDisposition?: GetImageSeriesValuesZipExpectedContentDispositionEnum;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetIntAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetLeaderboardRequest {
    entryType?: Array<string>;
    filterId?: string;
    flattingMode?: string;
    groupBy?: Array<string>;
    groupByFieldAggregationMode?: GetLeaderboardGroupByFieldAggregationModeEnum;
    groupId?: string;
    groupShortId?: Array<string>;
    limit?: number;
    minRunningTimeSeconds?: number;
    offset?: number;
    openedGroups?: Array<string>;
    organizationIdentifier?: string;
    owner?: Array<string>;
    projectIdentifier?: string;
    selectedChannels?: Array<string>;
    shortId?: Array<string>;
    sortBy?: Array<string>;
    sortDirection?: GetLeaderboardSortDirectionEnum;
    sortFieldAggregationMode?: GetLeaderboardSortFieldAggregationModeEnum;
    tags?: Array<string>;
    tagsMode?: GetLeaderboardTagsModeEnum;
    trashed?: GetLeaderboardTrashedEnum;
    state?: Array<ExperimentStateDTO>;
    groupByFieldType?: Array<AttributeTypeDTO>;
    sortFieldType?: Array<AttributeTypeDTO>;
}

export interface GetLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
}

export interface GetMultipleFloatSeriesValuesCSVRequest {
    request: AttributesRequestsListDTO;
}

export interface GetNotebookRequest {
    id: string;
}

export interface GetProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
}

export interface GetSeriesViewRequest {
    projectIdentifier: string;
    request: Array<SeriesViewRequestDTO>;
    pointCount?: number;
}

export interface GetStringAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSeriesAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSeriesValuesRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
    limit?: number;
    offset?: number;
}

export interface GetStringSeriesValuesCSVRequest {
    attribute: string;
    expectedContentDisposition?: GetStringSeriesValuesCSVExpectedContentDispositionEnum;
    experimentId?: string;
    gzipped?: boolean;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetStringSetAttributeRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface GetTimeSeriesViewRequest {
    projectIdentifier: string;
    request: TimeSeriesViewRequest;
}

export interface ListCheckpointsRequest {
    notebookId: string;
    id?: Array<string>;
    limit?: number;
    maxCreationTime?: Date;
    minCreationTime?: Date;
    offset?: number;
    owner?: Array<string>;
    searchTerm?: string;
}

export interface ListDashboardBranchVersionsRequest {
    projectIdentifier: string;
    versionBranch: string;
    drafts?: ListDashboardBranchVersionsDraftsEnum;
    limit?: number;
    offset?: number;
    substringQuery?: string;
}

export interface ListDashboardsRequest {
    projectIdentifier: string;
    branchIds?: Array<string>;
    dashboardType?: ListDashboardsDashboardTypeEnum;
    drafts?: ListDashboardsDraftsEnum;
    owners?: Array<string>;
}

export interface ListLeaderboardViewsRequest {
    projectIdentifier: string;
}

export interface ListNotebooksRequest {
    projectIdentifier: string;
    id?: Array<string>;
    limit?: number;
    maxCreationTime?: Date;
    minCreationTime?: Date;
    offset?: number;
    owner?: Array<string>;
    sortBy?: ListNotebooksSortByEnum;
    sortDirection?: ListNotebooksSortDirectionEnum;
}

export interface ListProjectAliasesRequest {
    projectIdentifier: string;
}

export interface ListProjectChartsRequest {
    projectIdentifier: string;
}

export interface ListTrackingDataRequest {
    projectIdentifiers: Array<string>;
}

export interface LsFileSetAttributeRequest {
    attribute: string;
    path: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface MultiSearchLeaderboardEntriesRequest {
    params: Array<MultiSearchLeaderboardEntriesParamsDTO>;
}

export interface PingRequest {
    experimentId?: string;
}

export interface PrepareForDownloadFileSetAttributeZipRequest {
    attribute: string;
    experimentId?: string;
    holderIdentifier?: string;
    holderType?: string;
}

export interface PrepareForDownloadLeaderboardCsvRequest {
    projectIdentifier: string;
    csvExporting: LeaderboardCsvExportParamsDTO;
}

export interface PutAttributeStyleSettingsRequest {
    params: AttributeStyleSettingsParamsDTO;
}

export interface QueryAttributeDefinitionsRequest {
    experimentIdentifier: string;
    attributeType?: Array<string>;
}

export interface QueryAttributeDefinitionsOnlyWithDiffsRequest {
    projectIdentifier: string;
    query: QueryAttributeDefinitionsBodyDTO;
}

export interface QueryAttributeDefinitionsPrioritizedRequest {
    query: QueryAttributeDefinitionsPrioritizedBodyDTO;
}

export interface QueryAttributeDefinitionsProtoRequest {
    experimentIdentifier: string;
    attributeType?: Array<string>;
}

export interface QueryAttributeDefinitionsWithinProjectRequest {
    projectIdentifier: string;
    query: QueryAttributeDefinitionsBodyDTO;
}

export interface QueryAttributeStyleSettingsRequest {
    params: SearchAttributesStyleSettingsParamsDTO;
}

export interface QueryAttributesWithinProjectRequest {
    projectIdentifier: string;
    query: QueryAttributesBodyDTO;
}

export interface RestoreAllExperimentsRequest {
    projectIdentifier: string;
}

export interface RestoreExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface SearchExperimentLeadersRequest {
    searchDto: ExperimentLeadersSearchDTO;
}

export interface SearchLeaderboardAttributesRequest {
    projectIdentifier: string;
    params: SearchLeaderboardParamsDTO;
    attributeType?: Array<string>;
    limit?: number;
    search?: string;
    type?: Array<string>;
}

export interface SearchLeaderboardEntriesRequest {
    projectIdentifier: string;
    params: SearchLeaderboardEntriesParamsDTO;
    type?: Array<string>;
}

export interface SearchLeaderboardEntriesProtoRequest {
    projectIdentifier: string;
    params: SearchLeaderboardEntriesParamsDTO;
    type?: Array<string>;
}

export interface SearchLeaderboardTagsRequest {
    projectIdentifier: string;
    params: SearchLeaderboardParamsDTO;
    attributePath?: SearchLeaderboardTagsAttributePathEnum;
    search?: string;
    type?: Array<string>;
}

export interface SetDefaultViewRequest {
    setDefaultOperation: LeaderboardViewSetDefaultDTO;
}

export interface SignalRunsRequest {
    projectIdentifier: string;
    signal: Signal;
}

export interface StackedQueryAttributesWithinProjectRequest {
    projectIdentifier: string;
    query: QueryAttributesBodyDTO;
}

export interface SuggestionDeclineRequest {
    projectIdentifier: string;
    suggestion: LeaderboardFieldSuggestionDTO;
    viewId?: string;
}

export interface TrashExperimentsRequest {
    projectIdentifier: string;
    experimentIdentifiers?: Array<string>;
}

export interface UpdateAliasRequest {
    aliasId: string;
    aliasToUpdate: AliasParams;
}

export interface UpdateCheckpointRequest {
    id: string;
    checkpointUpdate: CheckpointUpdateDTO;
}

export interface UpdateDashboardRequest {
    dashboardId: string;
    projectIdentifier: string;
    updateDashboard: UpdateDashboardDTO;
}

export interface UpdateDashboardVersionRequest {
    dashboardId: string;
    projectIdentifier: string;
    updateDashboardVersionDTO: UpdateDashboardVersionDTO;
    updateTimestamp?: boolean;
}

export interface UpdateLeaderboardViewRequest {
    projectIdentifier: string;
    viewId: string;
    updateView: LeaderboardViewUpdateDTO;
}

export interface UpdateNotebookRequest {
    id: string;
    notebookUpdate: NotebookUpdateDTO;
}

export interface UpdateProjectChartRequest {
    chartId: string;
    projectIdentifier: string;
    update: ProjectChartUpdateDTO;
}

export interface UpdateTags2Request {
    projectIdentifier: string;
    updateTagsParams: UpdateTagsParams;
}

export interface UploadAttributeRequest {
    attribute: string;
    ext: string;
    experimentId?: string;
}

export interface UploadCheckpointContentRequest {
    id: string;
}

export interface UploadFileSetAttributeChunkRequest {
    attribute: string;
    path: string;
    experimentId?: string;
    reset?: boolean;
}

export interface UploadFileSetAttributeTarRequest {
    attribute: string;
    experimentId?: string;
    reset?: boolean;
}

export interface ValidateExperimentsClipboardRequest {
    projectIdentifier: string;
    trashed: boolean;
    experimentIdentifiers?: Array<string>;
}

/**
 * no description
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Compare notebook checkpoint content, response is in nbdime rest api format
     */
    async compareCheckpointsContentRaw(requestParameters: CompareCheckpointsContentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.sourceCheckpointId === null || requestParameters.sourceCheckpointId === undefined) {
            throw new runtime.RequiredError('sourceCheckpointId','Required parameter requestParameters.sourceCheckpointId was null or undefined when calling compareCheckpointsContent.');
        }

        if (requestParameters.targetCheckpointId === null || requestParameters.targetCheckpointId === undefined) {
            throw new runtime.RequiredError('targetCheckpointId','Required parameter requestParameters.targetCheckpointId was null or undefined when calling compareCheckpointsContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sourceCheckpointId !== undefined) {
            queryParameters['sourceCheckpointId'] = requestParameters.sourceCheckpointId;
        }

        if (requestParameters.targetCheckpointId !== undefined) {
            queryParameters['targetCheckpointId'] = requestParameters.targetCheckpointId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/compare`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Compare notebook checkpoint content, response is in nbdime rest api format
     */
    async compareCheckpointsContent(requestParameters: CompareCheckpointsContentRequest): Promise<Blob> {
        const response = await this.compareCheckpointsContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create alias
     */
    async createAliasRaw(requestParameters: CreateAliasRequest): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createAlias.');
        }

        if (requestParameters.aliasToCreate === null || requestParameters.aliasToCreate === undefined) {
            throw new runtime.RequiredError('aliasToCreate','Required parameter requestParameters.aliasToCreate was null or undefined when calling createAlias.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AliasParamsToJSON(requestParameters.aliasToCreate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Create alias
     */
    async createAlias(requestParameters: CreateAliasRequest): Promise<Alias> {
        const response = await this.createAliasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create checkpoint
     */
    async createCheckpointRaw(requestParameters: CreateCheckpointRequest): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters.jupyterPath === null || requestParameters.jupyterPath === undefined) {
            throw new runtime.RequiredError('jupyterPath','Required parameter requestParameters.jupyterPath was null or undefined when calling createCheckpoint.');
        }

        if (requestParameters.notebookId === null || requestParameters.notebookId === undefined) {
            throw new runtime.RequiredError('notebookId','Required parameter requestParameters.notebookId was null or undefined when calling createCheckpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.description !== undefined) {
            queryParameters['description'] = requestParameters.description;
        }

        if (requestParameters.jupyterPath !== undefined) {
            queryParameters['jupyterPath'] = requestParameters.jupyterPath;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{notebookId}/checkpoints`.replace(`{${"notebookId"}}`, encodeURIComponent(String(requestParameters.notebookId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Create checkpoint
     */
    async createCheckpoint(requestParameters: CreateCheckpointRequest): Promise<NotebookDTO> {
        const response = await this.createCheckpointRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create dashboard
     */
    async createDashboardRaw(requestParameters: CreateDashboardRequest): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createDashboard.');
        }

        if (requestParameters.createDashboard === null || requestParameters.createDashboard === undefined) {
            throw new runtime.RequiredError('createDashboard','Required parameter requestParameters.createDashboard was null or undefined when calling createDashboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewDashboardDTOToJSON(requestParameters.createDashboard),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Create dashboard
     */
    async createDashboard(requestParameters: CreateDashboardRequest): Promise<DashboardDTO> {
        const response = await this.createDashboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create checkpoint
     */
    async createEmptyCheckpointRaw(requestParameters: CreateEmptyCheckpointRequest): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters.notebookId === null || requestParameters.notebookId === undefined) {
            throw new runtime.RequiredError('notebookId','Required parameter requestParameters.notebookId was null or undefined when calling createEmptyCheckpoint.');
        }

        if (requestParameters.checkpoint === null || requestParameters.checkpoint === undefined) {
            throw new runtime.RequiredError('checkpoint','Required parameter requestParameters.checkpoint was null or undefined when calling createEmptyCheckpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{notebookId}/checkpoints2`.replace(`{${"notebookId"}}`, encodeURIComponent(String(requestParameters.notebookId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCheckpointDTOToJSON(requestParameters.checkpoint),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Create checkpoint
     */
    async createEmptyCheckpoint(requestParameters: CreateEmptyCheckpointRequest): Promise<CheckpointDTO> {
        const response = await this.createEmptyCheckpointRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create experiment
     */
    async createExperimentRaw(requestParameters: CreateExperimentRequest): Promise<runtime.ApiResponse<Experiment>> {
        if (requestParameters.experimentCreationParams === null || requestParameters.experimentCreationParams === undefined) {
            throw new runtime.RequiredError('experimentCreationParams','Required parameter requestParameters.experimentCreationParams was null or undefined when calling createExperiment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['User-Agent'] = String(requestParameters.userAgent);
        }

        if (requestParameters.xNeptuneCliVersion !== undefined && requestParameters.xNeptuneCliVersion !== null) {
            headerParameters['X-Neptune-CliVersion'] = String(requestParameters.xNeptuneCliVersion);
        }

        if (requestParameters.xNeptuneLegacyClient !== undefined && requestParameters.xNeptuneLegacyClient !== null) {
            headerParameters['X-Neptune-LegacyClient'] = String(requestParameters.xNeptuneLegacyClient);
        }

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperimentCreationParamsToJSON(requestParameters.experimentCreationParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentFromJSON(jsonValue));
    }

    /**
     * Create experiment
     */
    async createExperiment(requestParameters: CreateExperimentRequest): Promise<Experiment> {
        const response = await this.createExperimentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create view in project
     */
    async createLeaderboardViewRaw(requestParameters: CreateLeaderboardViewRequest): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createLeaderboardView.');
        }

        if (requestParameters.createView === null || requestParameters.createView === undefined) {
            throw new runtime.RequiredError('createView','Required parameter requestParameters.createView was null or undefined when calling createLeaderboardView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewLeaderboardViewDTOToJSON(requestParameters.createView),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Create view in project
     */
    async createLeaderboardView(requestParameters: CreateLeaderboardViewRequest): Promise<LeaderboardViewDTO> {
        const response = await this.createLeaderboardViewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create new updated version of dashboard
     */
    async createNewVersionOfDashboardRaw(requestParameters: CreateNewVersionOfDashboardRequest): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling createNewVersionOfDashboard.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createNewVersionOfDashboard.');
        }

        if (requestParameters.createVersionDashboard === null || requestParameters.createVersionDashboard === undefined) {
            throw new runtime.RequiredError('createVersionDashboard','Required parameter requestParameters.createVersionDashboard was null or undefined when calling createNewVersionOfDashboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}/version`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewVersionDashboardDTOToJSON(requestParameters.createVersionDashboard),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Create new updated version of dashboard
     */
    async createNewVersionOfDashboard(requestParameters: CreateNewVersionOfDashboardRequest): Promise<DashboardDTO> {
        const response = await this.createNewVersionOfDashboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create notebook
     */
    async createNotebookRaw(requestParameters: CreateNotebookRequest): Promise<runtime.ApiResponse<NotebookWithNoContentDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createNotebook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookWithNoContentDTOFromJSON(jsonValue));
    }

    /**
     * Create notebook
     */
    async createNotebook(requestParameters: CreateNotebookRequest): Promise<NotebookWithNoContentDTO> {
        const response = await this.createNotebookRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create chart
     */
    async createProjectChartRaw(requestParameters: CreateProjectChartRequest): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling createProjectChart.');
        }

        if (requestParameters.chart === null || requestParameters.chart === undefined) {
            throw new runtime.RequiredError('chart','Required parameter requestParameters.chart was null or undefined when calling createProjectChart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}`.replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters.projectIdentifier))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewProjectChartDTOToJSON(requestParameters.chart),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Create chart
     */
    async createProjectChart(requestParameters: CreateProjectChartRequest): Promise<ProjectChartDTO> {
        const response = await this.createProjectChartRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete alias
     */
    async deleteAliasRaw(requestParameters: DeleteAliasRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.aliasId === null || requestParameters.aliasId === undefined) {
            throw new runtime.RequiredError('aliasId','Required parameter requestParameters.aliasId was null or undefined when calling deleteAlias.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters.aliasId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete alias
     */
    async deleteAlias(requestParameters: DeleteAliasRequest): Promise<void> {
        await this.deleteAliasRaw(requestParameters);
    }

    /**
     * Delete all versions of a dashboard by branch version
     */
    async deleteAllDashboardVersionsRaw(requestParameters: DeleteAllDashboardVersionsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.branchVersionId === null || requestParameters.branchVersionId === undefined) {
            throw new runtime.RequiredError('branchVersionId','Required parameter requestParameters.branchVersionId was null or undefined when calling deleteAllDashboardVersions.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteAllDashboardVersions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/branch/{branchVersionId}`.replace(`{${"branchVersionId"}}`, encodeURIComponent(String(requestParameters.branchVersionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete all versions of a dashboard by branch version
     */
    async deleteAllDashboardVersions(requestParameters: DeleteAllDashboardVersionsRequest): Promise<void> {
        await this.deleteAllDashboardVersionsRaw(requestParameters);
    }

    /**
     * Delete experiments from trash
     */
    async deleteAllExperimentsRaw(requestParameters: DeleteAllExperimentsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteAllExperiments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/deleteAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Delete experiments from trash
     */
    async deleteAllExperiments(requestParameters: DeleteAllExperimentsRequest): Promise<BulkOpResultDTO> {
        const response = await this.deleteAllExperimentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete checkpoint
     */
    async deleteCheckpointRaw(requestParameters: DeleteCheckpointRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCheckpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete checkpoint
     */
    async deleteCheckpoint(requestParameters: DeleteCheckpointRequest): Promise<void> {
        await this.deleteCheckpointRaw(requestParameters);
    }

    /**
     * Delete dashboard
     */
    async deleteDashboardRaw(requestParameters: DeleteDashboardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling deleteDashboard.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteDashboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete dashboard
     */
    async deleteDashboard(requestParameters: DeleteDashboardRequest): Promise<void> {
        await this.deleteDashboardRaw(requestParameters);
    }

    /**
     * Delete experiments from trash
     */
    async deleteExperimentsRaw(requestParameters: DeleteExperimentsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteExperiments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.experimentIdentifiers,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Delete experiments from trash
     */
    async deleteExperiments(requestParameters: DeleteExperimentsRequest): Promise<BulkOpResultDTO> {
        const response = await this.deleteExperimentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete view
     */
    async deleteLeaderboardViewRaw(requestParameters: DeleteLeaderboardViewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteLeaderboardView.');
        }

        if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
            throw new runtime.RequiredError('viewId','Required parameter requestParameters.viewId was null or undefined when calling deleteLeaderboardView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters.viewId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete view
     */
    async deleteLeaderboardView(requestParameters: DeleteLeaderboardViewRequest): Promise<void> {
        await this.deleteLeaderboardViewRaw(requestParameters);
    }

    /**
     * Delete notebook
     */
    async deleteNotebookRaw(requestParameters: DeleteNotebookRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteNotebook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete notebook
     */
    async deleteNotebook(requestParameters: DeleteNotebookRequest): Promise<void> {
        await this.deleteNotebookRaw(requestParameters);
    }

    /**
     * Delete chart
     */
    async deleteProjectChartRaw(requestParameters: DeleteProjectChartRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
            throw new runtime.RequiredError('chartId','Required parameter requestParameters.chartId was null or undefined when calling deleteProjectChart.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling deleteProjectChart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters.projectIdentifier))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete chart
     */
    async deleteProjectChart(requestParameters: DeleteProjectChartRequest): Promise<void> {
        await this.deleteProjectChartRaw(requestParameters);
    }

    /**
     * Get download stream
     */
    async downloadRaw(requestParameters: DownloadRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling download.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/download/downloadFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get download stream
     */
    async download(requestParameters: DownloadRequest): Promise<Blob> {
        const response = await this.downloadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download file attribute
     */
    async downloadAttributeRaw(requestParameters: DownloadAttributeRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling downloadAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.expectedContentDisposition !== undefined) {
            queryParameters['expectedContentDisposition'] = requestParameters.expectedContentDisposition;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download file attribute
     */
    async downloadAttribute(requestParameters: DownloadAttributeRequest): Promise<Blob> {
        const response = await this.downloadAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Execute operations
     */
    async executeOperationsRaw(requestParameters: ExecuteOperationsRequest): Promise<runtime.ApiResponse<Array<OperationError>>> {
        if (requestParameters.operations === null || requestParameters.operations === undefined) {
            throw new runtime.RequiredError('operations','Required parameter requestParameters.operations was null or undefined when calling executeOperations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['User-Agent'] = String(requestParameters.userAgent);
        }

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/operations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.operations.map(OperationToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OperationErrorFromJSON));
    }

    /**
     * Execute operations
     */
    async executeOperations(requestParameters: ExecuteOperationsRequest): Promise<Array<OperationError>> {
        const response = await this.executeOperationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get experiment stats for project
     */
    async experimentProjectStatsRaw(requestParameters: ExperimentProjectStatsRequest): Promise<runtime.ApiResponse<ExperimentStats>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling experimentProjectStats.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experimentStats/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentStatsFromJSON(jsonValue));
    }

    /**
     * Get experiment stats for project
     */
    async experimentProjectStats(requestParameters: ExperimentProjectStatsRequest): Promise<ExperimentStats> {
        const response = await this.experimentProjectStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get experiment stats for user
     */
    async experimentUserStatsRaw(requestParameters: ExperimentUserStatsRequest): Promise<runtime.ApiResponse<ExperimentStats>> {
        if (requestParameters.organization === null || requestParameters.organization === undefined) {
            throw new runtime.RequiredError('organization','Required parameter requestParameters.organization was null or undefined when calling experimentUserStats.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling experimentUserStats.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organization !== undefined) {
            queryParameters['organization'] = requestParameters.organization;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experimentStats/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentStatsFromJSON(jsonValue));
    }

    /**
     * Get experiment stats for user
     */
    async experimentUserStats(requestParameters: ExperimentUserStatsRequest): Promise<ExperimentStats> {
        const response = await this.experimentUserStatsRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadFinish
     */
    async fileAtomMultipartUploadFinishRaw(requestParameters: FileAtomMultipartUploadFinishRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileAtomMultipartUploadFinish.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileAtomMultipartUploadFinish.');
        }

        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling fileAtomMultipartUploadFinish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.uploadId !== undefined) {
            queryParameters['uploadId'] = requestParameters.uploadId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadFinish
     */
    async fileAtomMultipartUploadFinish(requestParameters: FileAtomMultipartUploadFinishRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomMultipartUploadFinishRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadPart
     */
    async fileAtomMultipartUploadPartRaw(requestParameters: FileAtomMultipartUploadPartRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileAtomMultipartUploadPart.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileAtomMultipartUploadPart.');
        }

        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling fileAtomMultipartUploadPart.');
        }

        if (requestParameters.uploadPartIdx === null || requestParameters.uploadPartIdx === undefined) {
            throw new runtime.RequiredError('uploadPartIdx','Required parameter requestParameters.uploadPartIdx was null or undefined when calling fileAtomMultipartUploadPart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.uploadId !== undefined) {
            queryParameters['uploadId'] = requestParameters.uploadId;
        }

        if (requestParameters.uploadPartIdx !== undefined) {
            queryParameters['uploadPartIdx'] = requestParameters.uploadPartIdx;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadPart
     */
    async fileAtomMultipartUploadPart(requestParameters: FileAtomMultipartUploadPartRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomMultipartUploadPartRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileAtomMultipartUploadStart
     */
    async fileAtomMultipartUploadStartRaw(requestParameters: FileAtomMultipartUploadStartRequest): Promise<runtime.ApiResponse<UploadMetadataDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileAtomMultipartUploadStart.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileAtomMultipartUploadStart.');
        }

        if (requestParameters.ext === null || requestParameters.ext === undefined) {
            throw new runtime.RequiredError('ext','Required parameter requestParameters.ext was null or undefined when calling fileAtomMultipartUploadStart.');
        }

        if (requestParameters.totalLength === null || requestParameters.totalLength === undefined) {
            throw new runtime.RequiredError('totalLength','Required parameter requestParameters.totalLength was null or undefined when calling fileAtomMultipartUploadStart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.ext !== undefined) {
            queryParameters['ext'] = requestParameters.ext;
        }

        if (requestParameters.totalLength !== undefined) {
            queryParameters['totalLength'] = requestParameters.totalLength;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadMetadataDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomMultipartUploadStart
     */
    async fileAtomMultipartUploadStart(requestParameters: FileAtomMultipartUploadStartRequest): Promise<UploadMetadataDTO> {
        const response = await this.fileAtomMultipartUploadStartRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileAtomUpload
     */
    async fileAtomUploadRaw(requestParameters: FileAtomUploadRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileAtomUpload.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileAtomUpload.');
        }

        if (requestParameters.ext === null || requestParameters.ext === undefined) {
            throw new runtime.RequiredError('ext','Required parameter requestParameters.ext was null or undefined when calling fileAtomUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.ext !== undefined) {
            queryParameters['ext'] = requestParameters.ext;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/file/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileAtomUpload
     */
    async fileAtomUpload(requestParameters: FileAtomUploadRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileAtomUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadFinish
     */
    async fileSetFileMultipartUploadFinishRaw(requestParameters: FileSetFileMultipartUploadFinishRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileSetFileMultipartUploadFinish.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileSetFileMultipartUploadFinish.');
        }

        if (requestParameters.subPath === null || requestParameters.subPath === undefined) {
            throw new runtime.RequiredError('subPath','Required parameter requestParameters.subPath was null or undefined when calling fileSetFileMultipartUploadFinish.');
        }

        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling fileSetFileMultipartUploadFinish.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.subPath !== undefined) {
            queryParameters['subPath'] = requestParameters.subPath;
        }

        if (requestParameters.uploadId !== undefined) {
            queryParameters['uploadId'] = requestParameters.uploadId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/finish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadFinish
     */
    async fileSetFileMultipartUploadFinish(requestParameters: FileSetFileMultipartUploadFinishRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileMultipartUploadFinishRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadPart
     */
    async fileSetFileMultipartUploadPartRaw(requestParameters: FileSetFileMultipartUploadPartRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileSetFileMultipartUploadPart.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileSetFileMultipartUploadPart.');
        }

        if (requestParameters.subPath === null || requestParameters.subPath === undefined) {
            throw new runtime.RequiredError('subPath','Required parameter requestParameters.subPath was null or undefined when calling fileSetFileMultipartUploadPart.');
        }

        if (requestParameters.uploadId === null || requestParameters.uploadId === undefined) {
            throw new runtime.RequiredError('uploadId','Required parameter requestParameters.uploadId was null or undefined when calling fileSetFileMultipartUploadPart.');
        }

        if (requestParameters.uploadPartIdx === null || requestParameters.uploadPartIdx === undefined) {
            throw new runtime.RequiredError('uploadPartIdx','Required parameter requestParameters.uploadPartIdx was null or undefined when calling fileSetFileMultipartUploadPart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.subPath !== undefined) {
            queryParameters['subPath'] = requestParameters.subPath;
        }

        if (requestParameters.uploadId !== undefined) {
            queryParameters['uploadId'] = requestParameters.uploadId;
        }

        if (requestParameters.uploadPartIdx !== undefined) {
            queryParameters['uploadPartIdx'] = requestParameters.uploadPartIdx;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadPart
     */
    async fileSetFileMultipartUploadPart(requestParameters: FileSetFileMultipartUploadPartRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileMultipartUploadPartRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileSetFileMultipartUploadStart
     */
    async fileSetFileMultipartUploadStartRaw(requestParameters: FileSetFileMultipartUploadStartRequest): Promise<runtime.ApiResponse<UploadMetadataDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileSetFileMultipartUploadStart.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileSetFileMultipartUploadStart.');
        }

        if (requestParameters.subPath === null || requestParameters.subPath === undefined) {
            throw new runtime.RequiredError('subPath','Required parameter requestParameters.subPath was null or undefined when calling fileSetFileMultipartUploadStart.');
        }

        if (requestParameters.totalLength === null || requestParameters.totalLength === undefined) {
            throw new runtime.RequiredError('totalLength','Required parameter requestParameters.totalLength was null or undefined when calling fileSetFileMultipartUploadStart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.subPath !== undefined) {
            queryParameters['subPath'] = requestParameters.subPath;
        }

        if (requestParameters.totalLength !== undefined) {
            queryParameters['totalLength'] = requestParameters.totalLength;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadMetadataDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileMultipartUploadStart
     */
    async fileSetFileMultipartUploadStart(requestParameters: FileSetFileMultipartUploadStartRequest): Promise<UploadMetadataDTO> {
        const response = await this.fileSetFileMultipartUploadStartRaw(requestParameters);
        return await response.value();
    }

    /**
     * fileSetFileUpload
     */
    async fileSetFileUploadRaw(requestParameters: FileSetFileUploadRequest): Promise<runtime.ApiResponse<OperationErrorsListDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling fileSetFileUpload.');
        }

        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling fileSetFileUpload.');
        }

        if (requestParameters.subPath === null || requestParameters.subPath === undefined) {
            throw new runtime.RequiredError('subPath','Required parameter requestParameters.subPath was null or undefined when calling fileSetFileUpload.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        if (requestParameters.subPath !== undefined) {
            queryParameters['subPath'] = requestParameters.subPath;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/storage/fileset/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OperationErrorsListDTOFromJSON(jsonValue));
    }

    /**
     * fileSetFileUpload
     */
    async fileSetFileUpload(requestParameters: FileSetFileUploadRequest): Promise<OperationErrorsListDTO> {
        const response = await this.fileSetFileUploadRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get alias
     */
    async getAliasRaw(requestParameters: GetAliasRequest): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters.aliasId === null || requestParameters.aliasId === undefined) {
            throw new runtime.RequiredError('aliasId','Required parameter requestParameters.aliasId was null or undefined when calling getAlias.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters.aliasId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Get alias
     */
    async getAlias(requestParameters: GetAliasRequest): Promise<Alias> {
        const response = await this.getAliasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get artifact attribute
     */
    async getArtifactAttributeRaw(requestParameters: GetArtifactAttributeRequest): Promise<runtime.ApiResponse<ArtifactAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getArtifactAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/artifacts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get artifact attribute
     */
    async getArtifactAttribute(requestParameters: GetArtifactAttributeRequest): Promise<ArtifactAttributeDTO> {
        const response = await this.getArtifactAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesRaw(requestParameters: GetAttributesRequest): Promise<runtime.ApiResponse<AttributesDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes
     */
    async getAttributes(requestParameters: GetAttributesRequest): Promise<AttributesDTO> {
        const response = await this.getAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterRaw(requestParameters: GetAttributesWithPathsFilterRequest): Promise<runtime.ApiResponse<AttributesDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeQueryDTOToJSON(requestParameters.attributeQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilter(requestParameters: GetAttributesWithPathsFilterRequest): Promise<AttributesDTO> {
        const response = await this.getAttributesWithPathsFilterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterProtoRaw(requestParameters: GetAttributesWithPathsFilterProtoRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.holderIdentifier === null || requestParameters.holderIdentifier === undefined) {
            throw new runtime.RequiredError('holderIdentifier','Required parameter requestParameters.holderIdentifier was null or undefined when calling getAttributesWithPathsFilterProto.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/attributes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeQueryDTOToJSON(requestParameters.attributeQuery),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get attributes
     */
    async getAttributesWithPathsFilterProto(requestParameters: GetAttributesWithPathsFilterProtoRequest): Promise<object> {
        const response = await this.getAttributesWithPathsFilterProtoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get organization audit data
     */
    async getAuditInfoRaw(requestParameters: GetAuditInfoRequest): Promise<runtime.ApiResponse<AuditInfo>> {
        if (requestParameters.organizationIdentifier === null || requestParameters.organizationIdentifier === undefined) {
            throw new runtime.RequiredError('organizationIdentifier','Required parameter requestParameters.organizationIdentifier was null or undefined when calling getAuditInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/audit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuditInfoFromJSON(jsonValue));
    }

    /**
     * Get organization audit data
     */
    async getAuditInfo(requestParameters: GetAuditInfoRequest): Promise<AuditInfo> {
        const response = await this.getAuditInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get boolean attribute
     */
    async getBoolAttributeRaw(requestParameters: GetBoolAttributeRequest): Promise<runtime.ApiResponse<BoolAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getBoolAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/booleans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BoolAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get boolean attribute
     */
    async getBoolAttribute(requestParameters: GetBoolAttributeRequest): Promise<BoolAttributeDTO> {
        const response = await this.getBoolAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get image
     */
    async getChannelImageRaw(requestParameters: GetChannelImageRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling getChannelImage.');
        }

        if (requestParameters.experimentId === null || requestParameters.experimentId === undefined) {
            throw new runtime.RequiredError('experimentId','Required parameter requestParameters.experimentId was null or undefined when calling getChannelImage.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling getChannelImage.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/images/{experimentId}/{channelId}/{fileName}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))).replace(`{${"experimentId"}}`, encodeURIComponent(String(requestParameters.experimentId))).replace(`{${"fileName"}}`, encodeURIComponent(String(requestParameters.fileName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get image
     */
    async getChannelImage(requestParameters: GetChannelImageRequest): Promise<Blob> {
        const response = await this.getChannelImageRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get values of a channel
     */
    async getChannelValuesRaw(requestParameters: GetChannelValuesRequest): Promise<runtime.ApiResponse<LimitedChannelValuesDTO>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling getChannelValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/channels/{channelId}/values`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LimitedChannelValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get values of a channel
     */
    async getChannelValues(requestParameters: GetChannelValuesRequest): Promise<LimitedChannelValuesDTO> {
        const response = await this.getChannelValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get notebook checkpoint
     */
    async getCheckpointRaw(requestParameters: GetCheckpointRequest): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook checkpoint
     */
    async getCheckpoint(requestParameters: GetCheckpointRequest): Promise<CheckpointDTO> {
        const response = await this.getCheckpointRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get notebook checkpoint content
     */
    async getCheckpointContentRaw(requestParameters: GetCheckpointContentRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckpointContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.expectedContentDisposition !== undefined) {
            queryParameters['expectedContentDisposition'] = requestParameters.expectedContentDisposition;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get notebook checkpoint content
     */
    async getCheckpointContent(requestParameters: GetCheckpointContentRequest): Promise<Blob> {
        const response = await this.getCheckpointContentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get object attribute
     */
    async getComplexAttributeRaw(requestParameters: GetComplexAttributeRequest): Promise<runtime.ApiResponse<ComplexAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getComplexAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/complexes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ComplexAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get object attribute
     */
    async getComplexAttribute(requestParameters: GetComplexAttributeRequest): Promise<ComplexAttributeDTO> {
        const response = await this.getComplexAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get dashboard
     */
    async getDashboardRaw(requestParameters: GetDashboardRequest): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling getDashboard.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling getDashboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Get dashboard
     */
    async getDashboard(requestParameters: GetDashboardRequest): Promise<DashboardDTO> {
        const response = await this.getDashboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get datetime attribute
     */
    async getDatetimeAttributeRaw(requestParameters: GetDatetimeAttributeRequest): Promise<runtime.ApiResponse<DatetimeAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getDatetimeAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/datetimes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DatetimeAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get datetime attribute
     */
    async getDatetimeAttribute(requestParameters: GetDatetimeAttributeRequest): Promise<DatetimeAttributeDTO> {
        const response = await this.getDatetimeAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get download request
     */
    async getDownloadPrepareRequestRaw(requestParameters: GetDownloadPrepareRequestRequest): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDownloadPrepareRequest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/download/downloadRequest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Get download request
     */
    async getDownloadPrepareRequest(requestParameters: GetDownloadPrepareRequestRequest): Promise<DownloadPrepareRequest> {
        const response = await this.getDownloadPrepareRequestRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get experiment
     */
    async getExperimentRaw(requestParameters: GetExperimentRequest): Promise<runtime.ApiResponse<Experiment>> {
        if (requestParameters.experimentId === null || requestParameters.experimentId === undefined) {
            throw new runtime.RequiredError('experimentId','Required parameter requestParameters.experimentId was null or undefined when calling getExperiment.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentFromJSON(jsonValue));
    }

    /**
     * Get experiment
     */
    async getExperiment(requestParameters: GetExperimentRequest): Promise<Experiment> {
        const response = await this.getExperimentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get experiment attributes
     */
    async getExperimentAttributesRaw(requestParameters: GetExperimentAttributesRequest): Promise<runtime.ApiResponse<ExperimentAttributesDTO>> {
        if (requestParameters.experimentId === null || requestParameters.experimentId === undefined) {
            throw new runtime.RequiredError('experimentId','Required parameter requestParameters.experimentId was null or undefined when calling getExperimentAttributes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentAttributesDTOFromJSON(jsonValue));
    }

    /**
     * Get experiment attributes
     */
    async getExperimentAttributes(requestParameters: GetExperimentAttributesRequest): Promise<ExperimentAttributesDTO> {
        const response = await this.getExperimentAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get file attribute
     */
    async getFileAttributeRaw(requestParameters: GetFileAttributeRequest): Promise<runtime.ApiResponse<FileAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFileAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/files`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get file attribute
     */
    async getFileAttribute(requestParameters: GetFileAttributeRequest): Promise<FileAttributeDTO> {
        const response = await this.getFileAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get float attribute
     */
    async getFloatAttributeRaw(requestParameters: GetFloatAttributeRequest): Promise<runtime.ApiResponse<FloatAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFloatAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get float attribute
     */
    async getFloatAttribute(requestParameters: GetFloatAttributeRequest): Promise<FloatAttributeDTO> {
        const response = await this.getFloatAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get float series attribute
     */
    async getFloatSeriesAttributeRaw(requestParameters: GetFloatSeriesAttributeRequest): Promise<runtime.ApiResponse<FloatSeriesAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFloatSeriesAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatSeriesAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get float series attribute
     */
    async getFloatSeriesAttribute(requestParameters: GetFloatSeriesAttributeRequest): Promise<FloatSeriesAttributeDTO> {
        const response = await this.getFloatSeriesAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesRaw(requestParameters: GetFloatSeriesValuesRequest): Promise<runtime.ApiResponse<FloatSeriesValuesDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFloatSeriesValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.skipToStep !== undefined) {
            queryParameters['skipToStep'] = requestParameters.skipToStep;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/float`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FloatSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValues(requestParameters: GetFloatSeriesValuesRequest): Promise<FloatSeriesValuesDTO> {
        const response = await this.getFloatSeriesValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get values of a float series
     */
    async getFloatSeriesValuesCSVRaw(requestParameters: GetFloatSeriesValuesCSVRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFloatSeriesValuesCSV.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.expectedContentDisposition !== undefined) {
            queryParameters['expectedContentDisposition'] = requestParameters.expectedContentDisposition;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.gzipped !== undefined) {
            queryParameters['gzipped'] = requestParameters.gzipped;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a float series
     */
    async getFloatSeriesValuesCSV(requestParameters: GetFloatSeriesValuesCSVRequest): Promise<Blob> {
        const response = await this.getFloatSeriesValuesCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesProtoRaw(requestParameters: GetFloatSeriesValuesProtoRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getFloatSeriesValuesProto.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.skipToStep !== undefined) {
            queryParameters['skipToStep'] = requestParameters.skipToStep;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/series/float`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get float series values
     */
    async getFloatSeriesValuesProto(requestParameters: GetFloatSeriesValuesProtoRequest): Promise<object> {
        const response = await this.getFloatSeriesValuesProtoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get image series single value
     */
    async getImageSeriesValueRaw(requestParameters: GetImageSeriesValueRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getImageSeriesValue.');
        }

        if (requestParameters.index === null || requestParameters.index === undefined) {
            throw new runtime.RequiredError('index','Required parameter requestParameters.index was null or undefined when calling getImageSeriesValue.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        if (requestParameters.index !== undefined) {
            queryParameters['index'] = requestParameters.index;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/image/single`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get image series single value
     */
    async getImageSeriesValue(requestParameters: GetImageSeriesValueRequest): Promise<Blob> {
        const response = await this.getImageSeriesValueRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get image series values
     */
    async getImageSeriesValuesRaw(requestParameters: GetImageSeriesValuesRequest): Promise<runtime.ApiResponse<ImageSeriesValuesDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getImageSeriesValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/image`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get image series values
     */
    async getImageSeriesValues(requestParameters: GetImageSeriesValuesRequest): Promise<ImageSeriesValuesDTO> {
        const response = await this.getImageSeriesValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get values of a image series
     */
    async getImageSeriesValuesZipRaw(requestParameters: GetImageSeriesValuesZipRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getImageSeriesValuesZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.expectedContentDisposition !== undefined) {
            queryParameters['expectedContentDisposition'] = requestParameters.expectedContentDisposition;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/imageSeries/zip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a image series
     */
    async getImageSeriesValuesZip(requestParameters: GetImageSeriesValuesZipRequest): Promise<Blob> {
        const response = await this.getImageSeriesValuesZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get integer attribute
     */
    async getIntAttributeRaw(requestParameters: GetIntAttributeRequest): Promise<runtime.ApiResponse<IntAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getIntAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/integers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IntAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get integer attribute
     */
    async getIntAttribute(requestParameters: GetIntAttributeRequest): Promise<IntAttributeDTO> {
        const response = await this.getIntAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async getLeaderboardRaw(requestParameters: GetLeaderboardRequest): Promise<runtime.ApiResponse<LeaderboardDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.entryType) {
            queryParameters['entryType'] = requestParameters.entryType;
        }

        if (requestParameters.filterId !== undefined) {
            queryParameters['filterId'] = requestParameters.filterId;
        }

        if (requestParameters.flattingMode !== undefined) {
            queryParameters['flattingMode'] = requestParameters.flattingMode;
        }

        if (requestParameters.groupBy) {
            queryParameters['groupBy'] = requestParameters.groupBy;
        }

        if (requestParameters.groupByFieldAggregationMode) {
            queryParameters['groupByFieldAggregationMode'] = requestParameters.groupByFieldAggregationMode;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        if (requestParameters.groupShortId) {
            queryParameters['groupShortId'] = requestParameters.groupShortId;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.minRunningTimeSeconds !== undefined) {
            queryParameters['minRunningTimeSeconds'] = requestParameters.minRunningTimeSeconds;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.openedGroups) {
            queryParameters['openedGroups'] = requestParameters.openedGroups;
        }

        if (requestParameters.organizationIdentifier !== undefined) {
            queryParameters['organizationIdentifier'] = requestParameters.organizationIdentifier;
        }

        if (requestParameters.owner) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.selectedChannels) {
            queryParameters['selectedChannels'] = requestParameters.selectedChannels;
        }

        if (requestParameters.shortId) {
            queryParameters['shortId'] = requestParameters.shortId;
        }

        if (requestParameters.sortBy) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDirection) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        if (requestParameters.sortFieldAggregationMode) {
            queryParameters['sortFieldAggregationMode'] = requestParameters.sortFieldAggregationMode;
        }

        if (requestParameters.tags) {
            queryParameters['tags'] = requestParameters.tags;
        }

        if (requestParameters.tagsMode !== undefined) {
            queryParameters['tagsMode'] = requestParameters.tagsMode;
        }

        if (requestParameters.trashed !== undefined) {
            queryParameters['trashed'] = requestParameters.trashed;
        }

        if (requestParameters.state) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.groupByFieldType) {
            queryParameters['groupByFieldType'] = requestParameters.groupByFieldType;
        }

        if (requestParameters.sortFieldType) {
            queryParameters['sortFieldType'] = requestParameters.sortFieldType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardDTOFromJSON(jsonValue));
    }

    /**
     * Get leaderboard
     */
    async getLeaderboard(requestParameters: GetLeaderboardRequest): Promise<LeaderboardDTO> {
        const response = await this.getLeaderboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get view
     */
    async getLeaderboardViewRaw(requestParameters: GetLeaderboardViewRequest): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling getLeaderboardView.');
        }

        if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
            throw new runtime.RequiredError('viewId','Required parameter requestParameters.viewId was null or undefined when calling getLeaderboardView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters.viewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Get view
     */
    async getLeaderboardView(requestParameters: GetLeaderboardViewRequest): Promise<LeaderboardViewDTO> {
        const response = await this.getLeaderboardViewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get multiple float series in single ZIP
     */
    async getMultipleFloatSeriesValuesCSVRaw(requestParameters: GetMultipleFloatSeriesValuesCSVRequest): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getMultipleFloatSeriesValuesCSV.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/floatSeries/csv/multiple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AttributesRequestsListDTOToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Get multiple float series in single ZIP
     */
    async getMultipleFloatSeriesValuesCSV(requestParameters: GetMultipleFloatSeriesValuesCSVRequest): Promise<DownloadPrepareRequest> {
        const response = await this.getMultipleFloatSeriesValuesCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get notebook
     */
    async getNotebookRaw(requestParameters: GetNotebookRequest): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNotebook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook
     */
    async getNotebook(requestParameters: GetNotebookRequest): Promise<NotebookDTO> {
        const response = await this.getNotebookRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get chart
     */
    async getProjectChartRaw(requestParameters: GetProjectChartRequest): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
            throw new runtime.RequiredError('chartId','Required parameter requestParameters.chartId was null or undefined when calling getProjectChart.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling getProjectChart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters.projectIdentifier))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Get chart
     */
    async getProjectChart(requestParameters: GetProjectChartRequest): Promise<ProjectChartDTO> {
        const response = await this.getProjectChartRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get series view by channel UUIDs
     */
    async getSeriesViewRaw(requestParameters: GetSeriesViewRequest): Promise<runtime.ApiResponse<SeriesViewListDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling getSeriesView.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getSeriesView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pointCount !== undefined) {
            queryParameters['pointCount'] = requestParameters.pointCount;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/channels/view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.request.map(SeriesViewRequestDTOToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SeriesViewListDTOFromJSON(jsonValue));
    }

    /**
     * Get series view by channel UUIDs
     */
    async getSeriesView(requestParameters: GetSeriesViewRequest): Promise<SeriesViewListDTO> {
        const response = await this.getSeriesViewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string attribute
     */
    async getStringAttributeRaw(requestParameters: GetStringAttributeRequest): Promise<runtime.ApiResponse<StringAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getStringAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/strings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StringAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string attribute
     */
    async getStringAttribute(requestParameters: GetStringAttributeRequest): Promise<StringAttributeDTO> {
        const response = await this.getStringAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string series attribute
     */
    async getStringSeriesAttributeRaw(requestParameters: GetStringSeriesAttributeRequest): Promise<runtime.ApiResponse<StringSeriesAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getStringSeriesAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSeries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSeriesAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string series attribute
     */
    async getStringSeriesAttribute(requestParameters: GetStringSeriesAttributeRequest): Promise<StringSeriesAttributeDTO> {
        const response = await this.getStringSeriesAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string series values
     */
    async getStringSeriesValuesRaw(requestParameters: GetStringSeriesValuesRequest): Promise<runtime.ApiResponse<StringSeriesValuesDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getStringSeriesValues.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/series/string`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSeriesValuesDTOFromJSON(jsonValue));
    }

    /**
     * Get string series values
     */
    async getStringSeriesValues(requestParameters: GetStringSeriesValuesRequest): Promise<StringSeriesValuesDTO> {
        const response = await this.getStringSeriesValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get values of a string series
     */
    async getStringSeriesValuesCSVRaw(requestParameters: GetStringSeriesValuesCSVRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getStringSeriesValuesCSV.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.expectedContentDisposition !== undefined) {
            queryParameters['expectedContentDisposition'] = requestParameters.expectedContentDisposition;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.gzipped !== undefined) {
            queryParameters['gzipped'] = requestParameters.gzipped;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSeries/csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get values of a string series
     */
    async getStringSeriesValuesCSV(requestParameters: GetStringSeriesValuesCSVRequest): Promise<Blob> {
        const response = await this.getStringSeriesValuesCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get string set attribute
     */
    async getStringSetAttributeRaw(requestParameters: GetStringSetAttributeRequest): Promise<runtime.ApiResponse<StringSetAttributeDTO>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling getStringSetAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/stringSets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StringSetAttributeDTOFromJSON(jsonValue));
    }

    /**
     * Get string set attribute
     */
    async getStringSetAttribute(requestParameters: GetStringSetAttributeRequest): Promise<StringSetAttributeDTO> {
        const response = await this.getStringSetAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get series view
     */
    async getTimeSeriesViewRaw(requestParameters: GetTimeSeriesViewRequest): Promise<runtime.ApiResponse<TimeSeriesViewResponse>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling getTimeSeriesView.');
        }

        if (requestParameters.request === null || requestParameters.request === undefined) {
            throw new runtime.RequiredError('request','Required parameter requestParameters.request was null or undefined when calling getTimeSeriesView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/timeseries/view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeSeriesViewRequestToJSON(requestParameters.request),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TimeSeriesViewResponseFromJSON(jsonValue));
    }

    /**
     * Get series view
     */
    async getTimeSeriesView(requestParameters: GetTimeSeriesViewRequest): Promise<TimeSeriesViewResponse> {
        const response = await this.getTimeSeriesViewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get notebook checkpoints
     */
    async listCheckpointsRaw(requestParameters: ListCheckpointsRequest): Promise<runtime.ApiResponse<CheckpointListDTO>> {
        if (requestParameters.notebookId === null || requestParameters.notebookId === undefined) {
            throw new runtime.RequiredError('notebookId','Required parameter requestParameters.notebookId was null or undefined when calling listCheckpoints.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.maxCreationTime !== undefined) {
            queryParameters['maxCreationTime'] = (requestParameters.maxCreationTime as any).toISOString();
        }

        if (requestParameters.minCreationTime !== undefined) {
            queryParameters['minCreationTime'] = (requestParameters.minCreationTime as any).toISOString();
        }

        if (requestParameters.notebookId !== undefined) {
            queryParameters['notebookId'] = requestParameters.notebookId;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.owner) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointListDTOFromJSON(jsonValue));
    }

    /**
     * Get notebook checkpoints
     */
    async listCheckpoints(requestParameters: ListCheckpointsRequest): Promise<CheckpointListDTO> {
        const response = await this.listCheckpointsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List dashboard versions of a branch
     */
    async listDashboardBranchVersionsRaw(requestParameters: ListDashboardBranchVersionsRequest): Promise<runtime.ApiResponse<PageDTODashboardVersionDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listDashboardBranchVersions.');
        }

        if (requestParameters.versionBranch === null || requestParameters.versionBranch === undefined) {
            throw new runtime.RequiredError('versionBranch','Required parameter requestParameters.versionBranch was null or undefined when calling listDashboardBranchVersions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.drafts !== undefined) {
            queryParameters['drafts'] = requestParameters.drafts;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.substringQuery !== undefined) {
            queryParameters['substringQuery'] = requestParameters.substringQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/list/versions/{versionBranch}`.replace(`{${"versionBranch"}}`, encodeURIComponent(String(requestParameters.versionBranch))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageDTODashboardVersionDTOFromJSON(jsonValue));
    }

    /**
     * List dashboard versions of a branch
     */
    async listDashboardBranchVersions(requestParameters: ListDashboardBranchVersionsRequest): Promise<PageDTODashboardVersionDTO> {
        const response = await this.listDashboardBranchVersionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List latest versions of dashboard
     */
    async listDashboardsRaw(requestParameters: ListDashboardsRequest): Promise<runtime.ApiResponse<DashboardListDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listDashboards.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.branchIds) {
            queryParameters['branchIds'] = requestParameters.branchIds;
        }

        if (requestParameters.dashboardType) {
            queryParameters['dashboardType'] = requestParameters.dashboardType;
        }

        if (requestParameters.drafts !== undefined) {
            queryParameters['drafts'] = requestParameters.drafts;
        }

        if (requestParameters.owners) {
            queryParameters['owners'] = requestParameters.owners;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardListDTOFromJSON(jsonValue));
    }

    /**
     * List latest versions of dashboard
     */
    async listDashboards(requestParameters: ListDashboardsRequest): Promise<DashboardListDTO> {
        const response = await this.listDashboardsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List views in project
     */
    async listLeaderboardViewsRaw(requestParameters: ListLeaderboardViewsRequest): Promise<runtime.ApiResponse<LeaderboardViewListDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listLeaderboardViews.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/views`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewListDTOFromJSON(jsonValue));
    }

    /**
     * List views in project
     */
    async listLeaderboardViews(requestParameters: ListLeaderboardViewsRequest): Promise<LeaderboardViewListDTO> {
        const response = await this.listLeaderboardViewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get notebooks
     */
    async listNotebooksRaw(requestParameters: ListNotebooksRequest): Promise<runtime.ApiResponse<NotebookListDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listNotebooks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.maxCreationTime !== undefined) {
            queryParameters['maxCreationTime'] = (requestParameters.maxCreationTime as any).toISOString();
        }

        if (requestParameters.minCreationTime !== undefined) {
            queryParameters['minCreationTime'] = (requestParameters.minCreationTime as any).toISOString();
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.owner) {
            queryParameters['owner'] = requestParameters.owner;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.sortBy) {
            queryParameters['sortBy'] = requestParameters.sortBy;
        }

        if (requestParameters.sortDirection) {
            queryParameters['sortDirection'] = requestParameters.sortDirection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookListDTOFromJSON(jsonValue));
    }

    /**
     * Get notebooks
     */
    async listNotebooks(requestParameters: ListNotebooksRequest): Promise<NotebookListDTO> {
        const response = await this.listNotebooksRaw(requestParameters);
        return await response.value();
    }

    /**
     * List project aliases
     */
    async listProjectAliasesRaw(requestParameters: ListProjectAliasesRequest): Promise<runtime.ApiResponse<Array<Alias>>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listProjectAliases.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AliasFromJSON));
    }

    /**
     * List project aliases
     */
    async listProjectAliases(requestParameters: ListProjectAliasesRequest): Promise<Array<Alias>> {
        const response = await this.listProjectAliasesRaw(requestParameters);
        return await response.value();
    }

    /**
     * listCharts
     */
    async listProjectChartsRaw(requestParameters: ListProjectChartsRequest): Promise<runtime.ApiResponse<ProjectChartListDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling listProjectCharts.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}`.replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters.projectIdentifier))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartListDTOFromJSON(jsonValue));
    }

    /**
     * listCharts
     */
    async listProjectCharts(requestParameters: ListProjectChartsRequest): Promise<ProjectChartListDTO> {
        const response = await this.listProjectChartsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List tracking data
     */
    async listTrackingDataRaw(requestParameters: ListTrackingDataRequest): Promise<runtime.ApiResponse<Array<TrackingDataDTO>>> {
        if (requestParameters.projectIdentifiers === null || requestParameters.projectIdentifiers === undefined) {
            throw new runtime.RequiredError('projectIdentifiers','Required parameter requestParameters.projectIdentifiers was null or undefined when calling listTrackingData.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/tracking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.projectIdentifiers,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TrackingDataDTOFromJSON));
    }

    /**
     * List tracking data
     */
    async listTrackingData(requestParameters: ListTrackingDataRequest): Promise<Array<TrackingDataDTO>> {
        const response = await this.listTrackingDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * List files in attribute of type File Set
     */
    async lsFileSetAttributeRaw(requestParameters: LsFileSetAttributeRequest): Promise<runtime.ApiResponse<Array<FileEntry>>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling lsFileSetAttribute.');
        }

        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling lsFileSetAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/ls`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FileEntryFromJSON));
    }

    /**
     * List files in attribute of type File Set
     */
    async lsFileSetAttribute(requestParameters: LsFileSetAttributeRequest): Promise<Array<FileEntry>> {
        const response = await this.lsFileSetAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Multi search leaderboard entries
     */
    async multiSearchLeaderboardEntriesRaw(requestParameters: MultiSearchLeaderboardEntriesRequest): Promise<runtime.ApiResponse<Array<LeaderboardEntriesSearchResultDTO>>> {
        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling multiSearchLeaderboardEntries.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/entries/msearch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.params.map(MultiSearchLeaderboardEntriesParamsDTOToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LeaderboardEntriesSearchResultDTOFromJSON));
    }

    /**
     * Multi search leaderboard entries
     */
    async multiSearchLeaderboardEntries(requestParameters: MultiSearchLeaderboardEntriesRequest): Promise<Array<LeaderboardEntriesSearchResultDTO>> {
        const response = await this.multiSearchLeaderboardEntriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Ping experiment
     */
    async pingRaw(requestParameters: PingRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/ping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Ping experiment
     */
    async ping(requestParameters: PingRequest): Promise<void> {
        await this.pingRaw(requestParameters);
    }

    /**
     * Creates fileset attribute\'s zip download request
     */
    async prepareForDownloadFileSetAttributeZipRaw(requestParameters: PrepareForDownloadFileSetAttributeZipRequest): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling prepareForDownloadFileSetAttributeZip.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.holderIdentifier !== undefined) {
            queryParameters['holderIdentifier'] = requestParameters.holderIdentifier;
        }

        if (requestParameters.holderType !== undefined) {
            queryParameters['holderType'] = requestParameters.holderType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/downloadFileSetZipRequest`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Creates fileset attribute\'s zip download request
     */
    async prepareForDownloadFileSetAttributeZip(requestParameters: PrepareForDownloadFileSetAttributeZipRequest): Promise<DownloadPrepareRequest> {
        const response = await this.prepareForDownloadFileSetAttributeZipRaw(requestParameters);
        return await response.value();
    }

    /**
     * Prepare download leaderboard as CSV
     */
    async prepareForDownloadLeaderboardCsvRaw(requestParameters: PrepareForDownloadLeaderboardCsvRequest): Promise<runtime.ApiResponse<DownloadPrepareRequest>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling prepareForDownloadLeaderboardCsv.');
        }

        if (requestParameters.csvExporting === null || requestParameters.csvExporting === undefined) {
            throw new runtime.RequiredError('csvExporting','Required parameter requestParameters.csvExporting was null or undefined when calling prepareForDownloadLeaderboardCsv.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/prepareForDownloadLeaderboardCsv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardCsvExportParamsDTOToJSON(requestParameters.csvExporting),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPrepareRequestFromJSON(jsonValue));
    }

    /**
     * Prepare download leaderboard as CSV
     */
    async prepareForDownloadLeaderboardCsv(requestParameters: PrepareForDownloadLeaderboardCsvRequest): Promise<DownloadPrepareRequest> {
        const response = await this.prepareForDownloadLeaderboardCsvRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates or updates attribute style settings
     */
    async putAttributeStyleSettingsRaw(requestParameters: PutAttributeStyleSettingsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling putAttributeStyleSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/style/putAttributeStyleSettings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AttributeStyleSettingsParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates or updates attribute style settings
     */
    async putAttributeStyleSettings(requestParameters: PutAttributeStyleSettingsRequest): Promise<void> {
        await this.putAttributeStyleSettingsRaw(requestParameters);
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitionsRaw(requestParameters: QueryAttributeDefinitionsRequest): Promise<runtime.ApiResponse<AttributesSearchResultDTO>> {
        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling queryAttributeDefinitions.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attributeType) {
            queryParameters['attributeType'] = requestParameters.attributeType;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/queryAttributeDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitions(requestParameters: QueryAttributeDefinitionsRequest): Promise<AttributesSearchResultDTO> {
        const response = await this.queryAttributeDefinitionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attribute definitions with different values
     */
    async queryAttributeDefinitionsOnlyWithDiffsRaw(requestParameters: QueryAttributeDefinitionsOnlyWithDiffsRequest): Promise<runtime.ApiResponse<QueryAttributeDefinitionsResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling queryAttributeDefinitionsOnlyWithDiffs.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling queryAttributeDefinitionsOnlyWithDiffs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/query-diffs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsBodyDTOToJSON(requestParameters.query),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions with different values
     */
    async queryAttributeDefinitionsOnlyWithDiffs(requestParameters: QueryAttributeDefinitionsOnlyWithDiffsRequest): Promise<QueryAttributeDefinitionsResultDTO> {
        const response = await this.queryAttributeDefinitionsOnlyWithDiffsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries prioritized attribute definitions, definitions that fulfill the priority queryare returned as first.
     */
    async queryAttributeDefinitionsPrioritizedRaw(requestParameters: QueryAttributeDefinitionsPrioritizedRequest): Promise<runtime.ApiResponse<QueryAttributeDefinitionsPrioritizedResultDTO>> {
        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling queryAttributeDefinitionsPrioritized.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/prioritized`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsPrioritizedBodyDTOToJSON(requestParameters.query),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsPrioritizedResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries prioritized attribute definitions, definitions that fulfill the priority queryare returned as first.
     */
    async queryAttributeDefinitionsPrioritized(requestParameters: QueryAttributeDefinitionsPrioritizedRequest): Promise<QueryAttributeDefinitionsPrioritizedResultDTO> {
        const response = await this.queryAttributeDefinitionsPrioritizedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitionsProtoRaw(requestParameters: QueryAttributeDefinitionsProtoRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.experimentIdentifier === null || requestParameters.experimentIdentifier === undefined) {
            throw new runtime.RequiredError('experimentIdentifier','Required parameter requestParameters.experimentIdentifier was null or undefined when calling queryAttributeDefinitionsProto.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attributeType) {
            queryParameters['attributeType'] = requestParameters.attributeType;
        }

        if (requestParameters.experimentIdentifier !== undefined) {
            queryParameters['experimentIdentifier'] = requestParameters.experimentIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/attributes/queryAttributeDefinitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Queries attribute definitions of an experiment
     */
    async queryAttributeDefinitionsProto(requestParameters: QueryAttributeDefinitionsProtoRequest): Promise<object> {
        const response = await this.queryAttributeDefinitionsProtoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attribute definitions
     */
    async queryAttributeDefinitionsWithinProjectRaw(requestParameters: QueryAttributeDefinitionsWithinProjectRequest): Promise<runtime.ApiResponse<QueryAttributeDefinitionsResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling queryAttributeDefinitionsWithinProject.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling queryAttributeDefinitionsWithinProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/definitions/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributeDefinitionsBodyDTOToJSON(requestParameters.query),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributeDefinitionsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attribute definitions
     */
    async queryAttributeDefinitionsWithinProject(requestParameters: QueryAttributeDefinitionsWithinProjectRequest): Promise<QueryAttributeDefinitionsResultDTO> {
        const response = await this.queryAttributeDefinitionsWithinProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attributes style settings
     */
    async queryAttributeStyleSettingsRaw(requestParameters: QueryAttributeStyleSettingsRequest): Promise<runtime.ApiResponse<AttributesStyleSettingsResultDTO>> {
        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling queryAttributeStyleSettings.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/style/queryAttributeStyleSettings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchAttributesStyleSettingsParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesStyleSettingsResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attributes style settings
     */
    async queryAttributeStyleSettings(requestParameters: QueryAttributeStyleSettingsRequest): Promise<AttributesStyleSettingsResultDTO> {
        const response = await this.queryAttributeStyleSettingsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProjectRaw(requestParameters: QueryAttributesWithinProjectRequest): Promise<runtime.ApiResponse<QueryAttributesResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling queryAttributesWithinProject.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling queryAttributesWithinProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributesBodyDTOToJSON(requestParameters.query),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributesResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attributes
     */
    async queryAttributesWithinProject(requestParameters: QueryAttributesWithinProjectRequest): Promise<QueryAttributesResultDTO> {
        const response = await this.queryAttributesWithinProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Restore experiments from trash
     */
    async restoreAllExperimentsRaw(requestParameters: RestoreAllExperimentsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling restoreAllExperiments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/restoreAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Restore experiments from trash
     */
    async restoreAllExperiments(requestParameters: RestoreAllExperimentsRequest): Promise<BulkOpResultDTO> {
        const response = await this.restoreAllExperimentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Restore experiments from trash
     */
    async restoreExperimentsRaw(requestParameters: RestoreExperimentsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling restoreExperiments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash/restore`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.experimentIdentifiers,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Restore experiments from trash
     */
    async restoreExperiments(requestParameters: RestoreExperimentsRequest): Promise<BulkOpResultDTO> {
        const response = await this.restoreExperimentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search for experiment leaders
     */
    async searchExperimentLeadersRaw(requestParameters: SearchExperimentLeadersRequest): Promise<runtime.ApiResponse<ExperimentLeadersSearchResultDTO>> {
        if (requestParameters.searchDto === null || requestParameters.searchDto === undefined) {
            throw new runtime.RequiredError('searchDto','Required parameter requestParameters.searchDto was null or undefined when calling searchExperimentLeaders.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiment/leaders/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExperimentLeadersSearchDTOToJSON(requestParameters.searchDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExperimentLeadersSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Search for experiment leaders
     */
    async searchExperimentLeaders(requestParameters: SearchExperimentLeadersRequest): Promise<ExperimentLeadersSearchResultDTO> {
        const response = await this.searchExperimentLeadersRaw(requestParameters);
        return await response.value();
    }

    /**
     * List definitions of all attributes in project
     */
    async searchLeaderboardAttributesRaw(requestParameters: SearchLeaderboardAttributesRequest): Promise<runtime.ApiResponse<AttributesSearchResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling searchLeaderboardAttributes.');
        }

        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling searchLeaderboardAttributes.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attributeType) {
            queryParameters['attributeType'] = requestParameters.attributeType;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AttributesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * List definitions of all attributes in project
     */
    async searchLeaderboardAttributes(requestParameters: SearchLeaderboardAttributesRequest): Promise<AttributesSearchResultDTO> {
        const response = await this.searchLeaderboardAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesRaw(requestParameters: SearchLeaderboardEntriesRequest): Promise<runtime.ApiResponse<LeaderboardEntriesSearchResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling searchLeaderboardEntries.');
        }

        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling searchLeaderboardEntries.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/entries/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardEntriesParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardEntriesSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntries(requestParameters: SearchLeaderboardEntriesRequest): Promise<LeaderboardEntriesSearchResultDTO> {
        const response = await this.searchLeaderboardEntriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesProtoRaw(requestParameters: SearchLeaderboardEntriesProtoRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling searchLeaderboardEntriesProto.');
        }

        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling searchLeaderboardEntriesProto.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/proto/leaderboard/entries/search/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardEntriesParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get leaderboard
     */
    async searchLeaderboardEntriesProto(requestParameters: SearchLeaderboardEntriesProtoRequest): Promise<object> {
        const response = await this.searchLeaderboardEntriesProtoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get tags defined in experiments within project
     */
    async searchLeaderboardTagsRaw(requestParameters: SearchLeaderboardTagsRequest): Promise<runtime.ApiResponse<TagsSearchResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling searchLeaderboardTags.');
        }

        if (requestParameters.params === null || requestParameters.params === undefined) {
            throw new runtime.RequiredError('params','Required parameter requestParameters.params was null or undefined when calling searchLeaderboardTags.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attributePath !== undefined) {
            queryParameters['attributePath'] = requestParameters.attributePath;
        }

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/tags/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchLeaderboardParamsDTOToJSON(requestParameters.params),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagsSearchResultDTOFromJSON(jsonValue));
    }

    /**
     * Get tags defined in experiments within project
     */
    async searchLeaderboardTags(requestParameters: SearchLeaderboardTagsRequest): Promise<TagsSearchResultDTO> {
        const response = await this.searchLeaderboardTagsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set default
     */
    async setDefaultViewRaw(requestParameters: SetDefaultViewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.setDefaultOperation === null || requestParameters.setDefaultOperation === undefined) {
            throw new runtime.RequiredError('setDefaultOperation','Required parameter requestParameters.setDefaultOperation was null or undefined when calling setDefaultView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views/setDefault`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardViewSetDefaultDTOToJSON(requestParameters.setDefaultOperation),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set default
     */
    async setDefaultView(requestParameters: SetDefaultViewRequest): Promise<void> {
        await this.setDefaultViewRaw(requestParameters);
    }

    /**
     * Signal Experiment
     */
    async signalRunsRaw(requestParameters: SignalRunsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling signalRuns.');
        }

        if (requestParameters.signal === null || requestParameters.signal === undefined) {
            throw new runtime.RequiredError('signal','Required parameter requestParameters.signal was null or undefined when calling signalRuns.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/signal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignalToJSON(requestParameters.signal),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Signal Experiment
     */
    async signalRuns(requestParameters: SignalRunsRequest): Promise<BulkOpResultDTO> {
        const response = await this.signalRunsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Queries attributes
     */
    async stackedQueryAttributesWithinProjectRaw(requestParameters: StackedQueryAttributesWithinProjectRequest): Promise<runtime.ApiResponse<QueryAttributesResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling stackedQueryAttributesWithinProject.');
        }

        if (requestParameters.query === null || requestParameters.query === undefined) {
            throw new runtime.RequiredError('query','Required parameter requestParameters.query was null or undefined when calling stackedQueryAttributesWithinProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/leaderboard/attributes/query/stacked`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QueryAttributesBodyDTOToJSON(requestParameters.query),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryAttributesResultDTOFromJSON(jsonValue));
    }

    /**
     * Queries attributes
     */
    async stackedQueryAttributesWithinProject(requestParameters: StackedQueryAttributesWithinProjectRequest): Promise<QueryAttributesResultDTO> {
        const response = await this.stackedQueryAttributesWithinProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     * Decline column suggestion from the project
     */
    async suggestionDeclineRaw(requestParameters: SuggestionDeclineRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling suggestionDecline.');
        }

        if (requestParameters.suggestion === null || requestParameters.suggestion === undefined) {
            throw new runtime.RequiredError('suggestion','Required parameter requestParameters.suggestion was null or undefined when calling suggestionDecline.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.viewId !== undefined) {
            queryParameters['viewId'] = requestParameters.viewId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/suggestions/decline`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardFieldSuggestionDTOToJSON(requestParameters.suggestion),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Decline column suggestion from the project
     */
    async suggestionDecline(requestParameters: SuggestionDeclineRequest): Promise<void> {
        await this.suggestionDeclineRaw(requestParameters);
    }

    /**
     * Trash experiments
     */
    async trashExperimentsRaw(requestParameters: TrashExperimentsRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling trashExperiments.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/trash`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.experimentIdentifiers,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Trash experiments
     */
    async trashExperiments(requestParameters: TrashExperimentsRequest): Promise<BulkOpResultDTO> {
        const response = await this.trashExperimentsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update alias
     */
    async updateAliasRaw(requestParameters: UpdateAliasRequest): Promise<runtime.ApiResponse<Alias>> {
        if (requestParameters.aliasId === null || requestParameters.aliasId === undefined) {
            throw new runtime.RequiredError('aliasId','Required parameter requestParameters.aliasId was null or undefined when calling updateAlias.');
        }

        if (requestParameters.aliasToUpdate === null || requestParameters.aliasToUpdate === undefined) {
            throw new runtime.RequiredError('aliasToUpdate','Required parameter requestParameters.aliasToUpdate was null or undefined when calling updateAlias.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/aliases/{aliasId}`.replace(`{${"aliasId"}}`, encodeURIComponent(String(requestParameters.aliasId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AliasParamsToJSON(requestParameters.aliasToUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AliasFromJSON(jsonValue));
    }

    /**
     * Update alias
     */
    async updateAlias(requestParameters: UpdateAliasRequest): Promise<Alias> {
        const response = await this.updateAliasRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update checkpoint
     */
    async updateCheckpointRaw(requestParameters: UpdateCheckpointRequest): Promise<runtime.ApiResponse<CheckpointDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCheckpoint.');
        }

        if (requestParameters.checkpointUpdate === null || requestParameters.checkpointUpdate === undefined) {
            throw new runtime.RequiredError('checkpointUpdate','Required parameter requestParameters.checkpointUpdate was null or undefined when calling updateCheckpoint.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CheckpointUpdateDTOToJSON(requestParameters.checkpointUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointDTOFromJSON(jsonValue));
    }

    /**
     * Update checkpoint
     */
    async updateCheckpoint(requestParameters: UpdateCheckpointRequest): Promise<CheckpointDTO> {
        const response = await this.updateCheckpointRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update dashboard
     */
    async updateDashboardRaw(requestParameters: UpdateDashboardRequest): Promise<runtime.ApiResponse<DashboardDTO>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling updateDashboard.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling updateDashboard.');
        }

        if (requestParameters.updateDashboard === null || requestParameters.updateDashboard === undefined) {
            throw new runtime.RequiredError('updateDashboard','Required parameter requestParameters.updateDashboard was null or undefined when calling updateDashboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardDTOToJSON(requestParameters.updateDashboard),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardDTOFromJSON(jsonValue));
    }

    /**
     * Update dashboard
     */
    async updateDashboard(requestParameters: UpdateDashboardRequest): Promise<DashboardDTO> {
        const response = await this.updateDashboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update dashboard version
     */
    async updateDashboardVersionRaw(requestParameters: UpdateDashboardVersionRequest): Promise<runtime.ApiResponse<DashboardVersionDTO>> {
        if (requestParameters.dashboardId === null || requestParameters.dashboardId === undefined) {
            throw new runtime.RequiredError('dashboardId','Required parameter requestParameters.dashboardId was null or undefined when calling updateDashboardVersion.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling updateDashboardVersion.');
        }

        if (requestParameters.updateDashboardVersionDTO === null || requestParameters.updateDashboardVersionDTO === undefined) {
            throw new runtime.RequiredError('updateDashboardVersionDTO','Required parameter requestParameters.updateDashboardVersionDTO was null or undefined when calling updateDashboardVersion.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.updateTimestamp !== undefined) {
            queryParameters['updateTimestamp'] = requestParameters.updateTimestamp;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/dashboards/{dashboardId}/version`.replace(`{${"dashboardId"}}`, encodeURIComponent(String(requestParameters.dashboardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDashboardVersionDTOToJSON(requestParameters.updateDashboardVersionDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardVersionDTOFromJSON(jsonValue));
    }

    /**
     * Update dashboard version
     */
    async updateDashboardVersion(requestParameters: UpdateDashboardVersionRequest): Promise<DashboardVersionDTO> {
        const response = await this.updateDashboardVersionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update view
     */
    async updateLeaderboardViewRaw(requestParameters: UpdateLeaderboardViewRequest): Promise<runtime.ApiResponse<LeaderboardViewDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling updateLeaderboardView.');
        }

        if (requestParameters.viewId === null || requestParameters.viewId === undefined) {
            throw new runtime.RequiredError('viewId','Required parameter requestParameters.viewId was null or undefined when calling updateLeaderboardView.');
        }

        if (requestParameters.updateView === null || requestParameters.updateView === undefined) {
            throw new runtime.RequiredError('updateView','Required parameter requestParameters.updateView was null or undefined when calling updateLeaderboardView.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/views/{viewId}`.replace(`{${"viewId"}}`, encodeURIComponent(String(requestParameters.viewId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeaderboardViewUpdateDTOToJSON(requestParameters.updateView),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LeaderboardViewDTOFromJSON(jsonValue));
    }

    /**
     * Update view
     */
    async updateLeaderboardView(requestParameters: UpdateLeaderboardViewRequest): Promise<LeaderboardViewDTO> {
        const response = await this.updateLeaderboardViewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update notebook
     */
    async updateNotebookRaw(requestParameters: UpdateNotebookRequest): Promise<runtime.ApiResponse<NotebookDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateNotebook.');
        }

        if (requestParameters.notebookUpdate === null || requestParameters.notebookUpdate === undefined) {
            throw new runtime.RequiredError('notebookUpdate','Required parameter requestParameters.notebookUpdate was null or undefined when calling updateNotebook.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: NotebookUpdateDTOToJSON(requestParameters.notebookUpdate),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NotebookDTOFromJSON(jsonValue));
    }

    /**
     * Update notebook
     */
    async updateNotebook(requestParameters: UpdateNotebookRequest): Promise<NotebookDTO> {
        const response = await this.updateNotebookRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update chart
     */
    async updateProjectChartRaw(requestParameters: UpdateProjectChartRequest): Promise<runtime.ApiResponse<ProjectChartDTO>> {
        if (requestParameters.chartId === null || requestParameters.chartId === undefined) {
            throw new runtime.RequiredError('chartId','Required parameter requestParameters.chartId was null or undefined when calling updateProjectChart.');
        }

        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling updateProjectChart.');
        }

        if (requestParameters.update === null || requestParameters.update === undefined) {
            throw new runtime.RequiredError('update','Required parameter requestParameters.update was null or undefined when calling updateProjectChart.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/projectCharts/{projectIdentifier}/{chartId}`.replace(`{${"chartId"}}`, encodeURIComponent(String(requestParameters.chartId))).replace(`{${"projectIdentifier"}}`, encodeURIComponent(String(requestParameters.projectIdentifier))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectChartUpdateDTOToJSON(requestParameters.update),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectChartDTOFromJSON(jsonValue));
    }

    /**
     * Update chart
     */
    async updateProjectChart(requestParameters: UpdateProjectChartRequest): Promise<ProjectChartDTO> {
        const response = await this.updateProjectChartRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update tags in experiments
     */
    async updateTags2Raw(requestParameters: UpdateTags2Request): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling updateTags2.');
        }

        if (requestParameters.updateTagsParams === null || requestParameters.updateTagsParams === undefined) {
            throw new runtime.RequiredError('updateTagsParams','Required parameter requestParameters.updateTagsParams was null or undefined when calling updateTags2.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTagsParamsToJSON(requestParameters.updateTagsParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Update tags in experiments
     */
    async updateTags2(requestParameters: UpdateTags2Request): Promise<BulkOpResultDTO> {
        const response = await this.updateTags2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Upload file attribute
     */
    async uploadAttributeRaw(requestParameters: UploadAttributeRequest): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling uploadAttribute.');
        }

        if (requestParameters.ext === null || requestParameters.ext === undefined) {
            throw new runtime.RequiredError('ext','Required parameter requestParameters.ext was null or undefined when calling uploadAttribute.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.ext !== undefined) {
            queryParameters['ext'] = requestParameters.ext;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload file attribute
     */
    async uploadAttribute(requestParameters: UploadAttributeRequest): Promise<OptionalOperationError> {
        const response = await this.uploadAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload checkpoint content
     */
    async uploadCheckpointContentRaw(requestParameters: UploadCheckpointContentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadCheckpointContent.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/notebooks/checkpoints/{id}/content`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload checkpoint content
     */
    async uploadCheckpointContent(requestParameters: UploadCheckpointContentRequest): Promise<void> {
        await this.uploadCheckpointContentRaw(requestParameters);
    }

    /**
     * Upload fileset attribute\'s file\'s chunk
     */
    async uploadFileSetAttributeChunkRaw(requestParameters: UploadFileSetAttributeChunkRequest): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling uploadFileSetAttributeChunk.');
        }

        if (requestParameters.path === null || requestParameters.path === undefined) {
            throw new runtime.RequiredError('path','Required parameter requestParameters.path was null or undefined when calling uploadFileSetAttributeChunk.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.path !== undefined) {
            queryParameters['path'] = requestParameters.path;
        }

        if (requestParameters.reset !== undefined) {
            queryParameters['reset'] = requestParameters.reset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/uploadFileSetChunk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload fileset attribute\'s file\'s chunk
     */
    async uploadFileSetAttributeChunk(requestParameters: UploadFileSetAttributeChunkRequest): Promise<OptionalOperationError> {
        const response = await this.uploadFileSetAttributeChunkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload fileset attribute\'s file\'s tar stream
     */
    async uploadFileSetAttributeTarRaw(requestParameters: UploadFileSetAttributeTarRequest): Promise<runtime.ApiResponse<OptionalOperationError>> {
        if (requestParameters.attribute === null || requestParameters.attribute === undefined) {
            throw new runtime.RequiredError('attribute','Required parameter requestParameters.attribute was null or undefined when calling uploadFileSetAttributeTar.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.attribute !== undefined) {
            queryParameters['attribute'] = requestParameters.attribute;
        }

        if (requestParameters.experimentId !== undefined) {
            queryParameters['experimentId'] = requestParameters.experimentId;
        }

        if (requestParameters.reset !== undefined) {
            queryParameters['reset'] = requestParameters.reset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/leaderboard/v1/attributes/uploadFileSetTar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalOperationErrorFromJSON(jsonValue));
    }

    /**
     * Upload fileset attribute\'s file\'s tar stream
     */
    async uploadFileSetAttributeTar(requestParameters: UploadFileSetAttributeTarRequest): Promise<OptionalOperationError> {
        const response = await this.uploadFileSetAttributeTarRaw(requestParameters);
        return await response.value();
    }

    /**
     * Validate experiments clipboard
     */
    async validateExperimentsClipboardRaw(requestParameters: ValidateExperimentsClipboardRequest): Promise<runtime.ApiResponse<BulkOpResultDTO>> {
        if (requestParameters.projectIdentifier === null || requestParameters.projectIdentifier === undefined) {
            throw new runtime.RequiredError('projectIdentifier','Required parameter requestParameters.projectIdentifier was null or undefined when calling validateExperimentsClipboard.');
        }

        if (requestParameters.trashed === null || requestParameters.trashed === undefined) {
            throw new runtime.RequiredError('trashed','Required parameter requestParameters.trashed was null or undefined when calling validateExperimentsClipboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectIdentifier !== undefined) {
            queryParameters['projectIdentifier'] = requestParameters.projectIdentifier;
        }

        if (requestParameters.trashed !== undefined) {
            queryParameters['trashed'] = requestParameters.trashed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/leaderboard/v1/experiments/select`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.experimentIdentifiers,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkOpResultDTOFromJSON(jsonValue));
    }

    /**
     * Validate experiments clipboard
     */
    async validateExperimentsClipboard(requestParameters: ValidateExperimentsClipboardRequest): Promise<BulkOpResultDTO> {
        const response = await this.validateExperimentsClipboardRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum DownloadAttributeExpectedContentDispositionEnum {
    Inline = 'inline',
    Attachment = 'attachment'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCheckpointContentExpectedContentDispositionEnum {
    Inline = 'inline',
    Attachment = 'attachment'
}
/**
    * @export
    * @enum {string}
    */
export enum GetFloatSeriesValuesCSVExpectedContentDispositionEnum {
    Inline = 'inline',
    Attachment = 'attachment'
}
/**
    * @export
    * @enum {string}
    */
export enum GetImageSeriesValuesZipExpectedContentDispositionEnum {
    Inline = 'inline',
    Attachment = 'attachment'
}
/**
    * @export
    * @enum {string}
    */
export enum GetLeaderboardGroupByFieldAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}
/**
    * @export
    * @enum {string}
    */
export enum GetLeaderboardSortDirectionEnum {
    Ascending = 'ascending',
    Descending = 'descending'
}
/**
    * @export
    * @enum {string}
    */
export enum GetLeaderboardSortFieldAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}
/**
    * @export
    * @enum {string}
    */
export enum GetLeaderboardTagsModeEnum {
    And = 'and',
    Or = 'or'
}
/**
    * @export
    * @enum {string}
    */
export enum GetLeaderboardTrashedEnum {
    False = 'false',
    True = 'true',
    All = 'all'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStringSeriesValuesCSVExpectedContentDispositionEnum {
    Inline = 'inline',
    Attachment = 'attachment'
}
/**
    * @export
    * @enum {string}
    */
export enum ListDashboardBranchVersionsDraftsEnum {
    False = 'false',
    True = 'true',
    All = 'all'
}
/**
    * @export
    * @enum {string}
    */
export enum ListDashboardsDashboardTypeEnum {
    Compare = 'compare',
    Experiment = 'experiment',
    Report = 'report'
}
/**
    * @export
    * @enum {string}
    */
export enum ListDashboardsDraftsEnum {
    False = 'false',
    True = 'true',
    All = 'all'
}
/**
    * @export
    * @enum {string}
    */
export enum ListNotebooksSortByEnum {
    Name = 'name',
    Owner = 'owner',
    UpdateTime = 'updateTime'
}
/**
    * @export
    * @enum {string}
    */
export enum ListNotebooksSortDirectionEnum {
    Ascending = 'ascending',
    Descending = 'descending'
}
/**
    * @export
    * @enum {string}
    */
export enum SearchLeaderboardTagsAttributePathEnum {
    Tags = 'sys/tags',
    GroupTags = 'sys/group_tags'
}
