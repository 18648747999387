import React from 'react';
import { UITooltip } from './UITooltip';
import { useTooltip } from './useTooltip';

export const SimpleTooltip: React.FC<{
  content?: React.ReactNode;
  noPointerEvents?: boolean;
  children?: React.ReactNode;
}> = ({ content, noPointerEvents, children }) => {
  const tooltipProps = useTooltip({
    placement: 'top-center',
  });

  if (!content) {
    return <>{children}</>;
  }

  return (
    <span {...tooltipProps.triggerProps}>
      {children}
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(
          <UITooltip {...tooltipProps.layerProps} noPointerEvents={noPointerEvents}>
            {content}
          </UITooltip>,
        )}
    </span>
  );
};
