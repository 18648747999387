/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomMetric,
    CustomMetricFromJSON,
    CustomMetricFromJSONTyped,
    CustomMetricToJSON,
    TimeSeries,
    TimeSeriesFromJSON,
    TimeSeriesFromJSONTyped,
    TimeSeriesToJSON,
    View,
    ViewFromJSON,
    ViewFromJSONTyped,
    ViewToJSON,
    XAxis,
    XAxisFromJSON,
    XAxisFromJSONTyped,
    XAxisToJSON,
} from './';

/**
 * 
 * @export
 * @interface TimeSeriesViewRequest
 */
export interface TimeSeriesViewRequest {
    /**
     * 
     * @type {Array<CustomMetric>}
     * @memberof TimeSeriesViewRequest
     */
    customMetrics?: Array<CustomMetric>;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesViewRequest
     */
    customYFormula?: string;
    /**
     * 
     * @type {Array<TimeSeries>}
     * @memberof TimeSeriesViewRequest
     */
    series?: Array<TimeSeries>;
    /**
     * 
     * @type {View}
     * @memberof TimeSeriesViewRequest
     */
    view: View;
    /**
     * 
     * @type {XAxis}
     * @memberof TimeSeriesViewRequest
     */
    xaxis: XAxis;
}

export function TimeSeriesViewRequestFromJSON(json: any): TimeSeriesViewRequest {
    return TimeSeriesViewRequestFromJSONTyped(json, false);
}

export function TimeSeriesViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesViewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customMetrics': !exists(json, 'customMetrics') ? undefined : ((json['customMetrics'] as Array<any>).map(CustomMetricFromJSON)),
        'customYFormula': !exists(json, 'customYFormula') ? undefined : json['customYFormula'],
        'series': !exists(json, 'series') ? undefined : ((json['series'] as Array<any>).map(TimeSeriesFromJSON)),
        'view': ViewFromJSON(json['view']),
        'xaxis': XAxisFromJSON(json['xaxis']),
    };
}

export function TimeSeriesViewRequestToJSON(value?: TimeSeriesViewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customMetrics': value.customMetrics === undefined ? undefined : ((value.customMetrics as Array<any>).map(CustomMetricToJSON)),
        'customYFormula': value.customYFormula,
        'series': value.series === undefined ? undefined : ((value.series as Array<any>).map(TimeSeriesToJSON)),
        'view': ViewToJSON(value.view),
        'xaxis': XAxisToJSON(value.xaxis),
    };
}


