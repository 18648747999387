/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentAttributesDTO,
    ExperimentAttributesDTOFromJSON,
    ExperimentAttributesDTOFromJSONTyped,
    ExperimentAttributesDTOToJSON,
    LeaderboardEntryGroupDTO,
    LeaderboardEntryGroupDTOFromJSON,
    LeaderboardEntryGroupDTOFromJSONTyped,
    LeaderboardEntryGroupDTOToJSON,
    LeaderboardFieldSuggestionDTO,
    LeaderboardFieldSuggestionDTOFromJSON,
    LeaderboardFieldSuggestionDTOFromJSONTyped,
    LeaderboardFieldSuggestionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardDTO
 */
export interface LeaderboardDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<ExperimentAttributesDTO>}
     * @memberof LeaderboardDTO
     */
    entries: Array<ExperimentAttributesDTO>;
    /**
     * The groups of entities matching given criteria.
     * @type {Array<LeaderboardEntryGroupDTO>}
     * @memberof LeaderboardDTO
     */
    groups?: Array<LeaderboardEntryGroupDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof LeaderboardDTO
     */
    matchingItemCount: number;
    /**
     * The column suggestions to the user, most recommended columns first.
     * @type {Array<LeaderboardFieldSuggestionDTO>}
     * @memberof LeaderboardDTO
     */
    suggestions?: Array<LeaderboardFieldSuggestionDTO>;
    /**
     * The total number of group entries.
     * @type {number}
     * @memberof LeaderboardDTO
     */
    totalGroupCount?: number;
    /**
     * The total number of entries.
     * @type {number}
     * @memberof LeaderboardDTO
     */
    totalItemCount: number;
}

export function LeaderboardDTOFromJSON(json: any): LeaderboardDTO {
    return LeaderboardDTOFromJSONTyped(json, false);
}

export function LeaderboardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(ExperimentAttributesDTOFromJSON)),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(LeaderboardEntryGroupDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'suggestions': !exists(json, 'suggestions') ? undefined : ((json['suggestions'] as Array<any>).map(LeaderboardFieldSuggestionDTOFromJSON)),
        'totalGroupCount': !exists(json, 'totalGroupCount') ? undefined : json['totalGroupCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function LeaderboardDTOToJSON(value?: LeaderboardDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(ExperimentAttributesDTOToJSON)),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(LeaderboardEntryGroupDTOToJSON)),
        'matchingItemCount': value.matchingItemCount,
        'suggestions': value.suggestions === undefined ? undefined : ((value.suggestions as Array<any>).map(LeaderboardFieldSuggestionDTOToJSON)),
        'totalGroupCount': value.totalGroupCount,
        'totalItemCount': value.totalItemCount,
    };
}


