import React from 'react';
import { isEqual } from 'lodash';

export function useMemoizedValue<T>(value: T) {
  const ref = React.useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}
