'use strict';

import { isObject } from 'lodash';
import urlParse from 'url-parse';
import baseConfig from './base';
import { customConfig } from './custom-config';

const dockerConfig = isObject(window.dockerConfig) ? compileDockerConfig(window.dockerConfig) : {};

const config = {
  appEnv: 'dist',
};

const featureFlags = {
  withViewsPhase1: true,
  withViewsPhase2: false,

  withAdminPanel: false,

  // active projects pricing flags
  withActiveProjectsPricing: true,
  withArchivedProjects: true,
  withLockedLegacyPricing: true,

  withScatterPlotWidget: true,
  withReports: true,

  withXAxisMetric: true,
  withCustomYAxis: true,
  withRefactoredCharts: false,

  withRunGroupsV4: false,

  withUnificationV1: true,

  withExperimentForksV1: false,
  withExperimentForksV2: false,
  withExperimentForksLeafOnlyToggle: true,
  withChartDynamicUniqueXPoints: true,
  withEmbedInNotionFeature: false,
  withConfigureChartsModal: false,
  withNewRelativeTime: true,
  withExperimentAutoFollow: false,
  withSectionsInReports: false,
  withSectionsInDashboards: false,

  // changed only for demo instance to do not show ingest API empty states
  withIngestApi: false,
};

export default Object.freeze(
  Object.assign({}, baseConfig, config, featureFlags, dockerConfig, customConfig()),
);

function compileDockerConfig(config) {
  // in case of relative logoutURL, always convert to absolute as on-prems have problems when hosting from IPs
  const logoutURL = config.logoutURL
    ? urlParse(config.logoutURL).toString()
    : window.location.origin;

  const allowAddingNewLicense = !!config.allowAddingNewLicense;

  return Object.assign({}, config, {
    neptuneApiWSPort: config.neptuneApiPort,
    logoutURL,
    allowAddingNewLicense,
  });
}
