/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeFilterDTO,
    AttributeFilterDTOFromJSON,
    AttributeFilterDTOFromJSONTyped,
    AttributeFilterDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FilterQueryAttributeDefinitionsDTO
 */
export interface FilterQueryAttributeDefinitionsDTO {
    /**
     * Filter by attribute (match any), if null no type or property value filtering is applied
     * @type {Array<AttributeFilterDTO>}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    attributeFilter?: Array<AttributeFilterDTO>;
    /**
     * Filter by attribute name with regex, if null no name filtering is applied
     * @type {string}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    attributeNameRegex?: string;
    /**
     * Project identifier
     * @type {string}
     * @memberof FilterQueryAttributeDefinitionsDTO
     */
    projectIdentifier: string;
}

export function FilterQueryAttributeDefinitionsDTOFromJSON(json: any): FilterQueryAttributeDefinitionsDTO {
    return FilterQueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function FilterQueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterQueryAttributeDefinitionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeFilter': !exists(json, 'attributeFilter') ? undefined : ((json['attributeFilter'] as Array<any>).map(AttributeFilterDTOFromJSON)),
        'attributeNameRegex': !exists(json, 'attributeNameRegex') ? undefined : json['attributeNameRegex'],
        'projectIdentifier': json['projectIdentifier'],
    };
}

export function FilterQueryAttributeDefinitionsDTOToJSON(value?: FilterQueryAttributeDefinitionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeFilter': value.attributeFilter === undefined ? undefined : ((value.attributeFilter as Array<any>).map(AttributeFilterDTOToJSON)),
        'attributeNameRegex': value.attributeNameRegex,
        'projectIdentifier': value.projectIdentifier,
    };
}


