/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardGroupParamsDTO,
    LeaderboardGroupParamsDTOFromJSON,
    LeaderboardGroupParamsDTOFromJSONTyped,
    LeaderboardGroupParamsDTOToJSON,
    LeaderboardSortParamsDTO,
    LeaderboardSortParamsDTOFromJSON,
    LeaderboardSortParamsDTOFromJSONTyped,
    LeaderboardSortParamsDTOToJSON,
    LeaderboardViewColumnListDTO,
    LeaderboardViewColumnListDTOFromJSON,
    LeaderboardViewColumnListDTOFromJSONTyped,
    LeaderboardViewColumnListDTOToJSON,
    LeaderboardViewQuickFilterDTO,
    LeaderboardViewQuickFilterDTOFromJSON,
    LeaderboardViewQuickFilterDTOFromJSONTyped,
    LeaderboardViewQuickFilterDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewLeaderboardViewDTO
 */
export interface NewLeaderboardViewDTO {
    /**
     * 
     * @type {LeaderboardViewColumnListDTO}
     * @memberof NewLeaderboardViewDTO
     */
    columnList: LeaderboardViewColumnListDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewLeaderboardViewDTO
     */
    defaultView: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NewLeaderboardViewDTO
     */
    experimentsOnly?: boolean;
    /**
     * 
     * @type {LeaderboardGroupParamsDTO}
     * @memberof NewLeaderboardViewDTO
     */
    groupOptions?: LeaderboardGroupParamsDTO;
    /**
     * 
     * @type {string}
     * @memberof NewLeaderboardViewDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewLeaderboardViewDTO
     */
    query?: string;
    /**
     * 
     * @type {LeaderboardViewQuickFilterDTO}
     * @memberof NewLeaderboardViewDTO
     */
    quickFilters?: LeaderboardViewQuickFilterDTO;
    /**
     * 
     * @type {LeaderboardSortParamsDTO}
     * @memberof NewLeaderboardViewDTO
     */
    sortOptions: LeaderboardSortParamsDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewLeaderboardViewDTO
     */
    suggestionsEnabled: boolean;
}

export function NewLeaderboardViewDTOFromJSON(json: any): NewLeaderboardViewDTO {
    return NewLeaderboardViewDTOFromJSONTyped(json, false);
}

export function NewLeaderboardViewDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewLeaderboardViewDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOFromJSON(json['columnList']),
        'defaultView': json['defaultView'],
        'experimentsOnly': !exists(json, 'experimentsOnly') ? undefined : json['experimentsOnly'],
        'groupOptions': !exists(json, 'groupOptions') ? undefined : LeaderboardGroupParamsDTOFromJSON(json['groupOptions']),
        'name': json['name'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'quickFilters': !exists(json, 'quickFilters') ? undefined : LeaderboardViewQuickFilterDTOFromJSON(json['quickFilters']),
        'sortOptions': LeaderboardSortParamsDTOFromJSON(json['sortOptions']),
        'suggestionsEnabled': json['suggestionsEnabled'],
    };
}

export function NewLeaderboardViewDTOToJSON(value?: NewLeaderboardViewDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columnList': LeaderboardViewColumnListDTOToJSON(value.columnList),
        'defaultView': value.defaultView,
        'experimentsOnly': value.experimentsOnly,
        'groupOptions': LeaderboardGroupParamsDTOToJSON(value.groupOptions),
        'name': value.name,
        'query': value.query,
        'quickFilters': LeaderboardViewQuickFilterDTOToJSON(value.quickFilters),
        'sortOptions': LeaderboardSortParamsDTOToJSON(value.sortOptions),
        'suggestionsEnabled': value.suggestionsEnabled,
    };
}


