export const prepareRegexpSearchWithAnchors = (input: string): string => {
  const userInput = input.trim();

  if (!userInput) {
    return '.*';
  }

  return `.*(${userInput}).*`
    .replace(/^...\^/, '(') // handles user provided ^ at the beginning
    .replace(/\$...$/, ')'); // handles user provided $ at the end
};
