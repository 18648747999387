import React from 'react';
import { useAsyncWrapper, UseAsyncWrapperParams } from './use-async-wrapper';

export type UseAsyncValueParams<T, E = any> = UseAsyncWrapperParams<void, T, E>;

export type UseAsyncValueReturns<T, E = any> = {
  loading: boolean;
  value?: T;
  error?: E;
  refresh(): Promise<void>;
};

export function useAsyncValue<T, E = any>(params: UseAsyncValueParams<T, E>) {
  const { error, loading, resolve, result } = useAsyncWrapper(params);

  React.useEffect(() => {
    resolve();
  }, [resolve]);

  const refresh = React.useCallback(async () => {
    resolve();
  }, [resolve]);

  return {
    loading,
    error,
    value: result,
    refresh,
  };
}
