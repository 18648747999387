/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    ProjectVisibilityDTO,
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewProjectDTO
 */
export interface NewProjectDTO {
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    description?: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof NewProjectDTO
     */
    avatarSource?: AvatarSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof NewProjectDTO
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    projectKey?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    organizationId: string;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof NewProjectDTO
     */
    visibility?: ProjectVisibilityDTO;
    /**
     * 
     * @type {string}
     * @memberof NewProjectDTO
     */
    displayClass?: string;
}

export function NewProjectDTOFromJSON(json: any): NewProjectDTO {
    return NewProjectDTOFromJSONTyped(json, false);
}

export function NewProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'avatarSource': !exists(json, 'avatarSource') ? undefined : AvatarSourceEnumFromJSON(json['avatarSource']),
        'version': !exists(json, 'version') ? undefined : json['version'],
        'projectKey': !exists(json, 'projectKey') ? undefined : json['projectKey'],
        'organizationId': json['organizationId'],
        'visibility': !exists(json, 'visibility') ? undefined : ProjectVisibilityDTOFromJSON(json['visibility']),
        'displayClass': !exists(json, 'displayClass') ? undefined : json['displayClass'],
    };
}

export function NewProjectDTOToJSON(value?: NewProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'avatarUrl': value.avatarUrl,
        'description': value.description,
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'version': value.version,
        'projectKey': value.projectKey,
        'organizationId': value.organizationId,
        'visibility': ProjectVisibilityDTOToJSON(value.visibility),
        'displayClass': value.displayClass,
    };
}


