import React from 'react';

import ContactLink from 'components/contact-link/ContactLink';
import { ErrorPage } from './ErrorPage';
import { bemBlock, Button, Layout, Text } from '@neptune/shared/venus-ui';
import { Textarea } from '../form-inputs/Textarea';
import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import './LicenseErrorPage.less';

const block = bemBlock('license-error-page');

type LicenseErrorPageProps = {
  onInputChange: (newValue: string) => void;
  onSubmit: () => void;
  enableSubmit: boolean;
  reloading: boolean;
  message: string;
};

export const LicenseErrorPage: React.FC<LicenseErrorPageProps> = ({
  onSubmit,
  onInputChange,
  enableSubmit,
  message,
  reloading,
}) => {
  return (
    <ErrorPage>
      <ErrorPage.Title>Your on-prem license has expired</ErrorPage.Title>
      <ErrorPage.Message>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <Layout.Column alignItems="center" className={block('content')} spacedChildren="sm">
            <label htmlFor="licence-box">
              <Text>Provide a valid license</Text>
            </label>
            <Textarea
              id="licence-box"
              className={block('textarea')}
              onChange={(event) => onInputChange(event.target.value)}
            />
            <Button size="xl" type="submit" disabled={!enableSubmit}>
              Apply
            </Button>
            <Text color="error">{message}</Text>

            {reloading && <LoadingIndicator isVisible centered />}
          </Layout.Column>
        </form>
      </ErrorPage.Message>
      <ErrorPage.Footer>
        If you haven't receive a new license yet, please <ContactLink>contact us</ContactLink>.
      </ErrorPage.Footer>
    </ErrorPage>
  );
};
