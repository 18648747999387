import React from 'react';
import AutosizeInput from 'react-input-autosize';
import { bemBlock } from '@neptune/shared/venus-ui';

import './InlineInput.less';

export type InlineInputProps = {
  value: string;
  className?: string;
  onSubmit?(value: string): void;
};

const block = bemBlock('n-inline-input');

export function InlineInput({ className, value, onSubmit }: InlineInputProps) {
  const skipChangeEvent = React.useRef(false);

  const [newValue, setNewValue] = React.useState(value);
  const [isFocused, setIsFocused] = React.useState(false);

  const onChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value);
  }, []);

  const onBlur = React.useCallback(() => {
    if (skipChangeEvent.current) {
      setNewValue(value);
    } else {
      onSubmit?.(newValue);
    }
  }, [newValue, onSubmit, value]);

  const onFocus = React.useCallback(() => {
    skipChangeEvent.current = false;
    setIsFocused(true);
  }, []);

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    if (event.key === 'Escape') {
      skipChangeEvent.current = true;
      target.blur();
    }

    if (event.key === 'Enter') {
      skipChangeEvent.current = false;
      target.blur();
    }
  }, []);

  const displayValue = isFocused ? newValue : value;

  return (
    <AutosizeInput
      inputClassName={block({ element: 'input', extra: className })}
      className={block('wrapper')}
      style={{ display: 'flex' }}
      onKeyDown={onKeyDown}
      value={displayValue}
      title={displayValue}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
